import { Tags } from './tags';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg';

const formatDuration = (duration, type) => {
  if (!duration || duration === '00:00') {
    return '0:00';
  }

  if (type === 'audio') {
    // Для аудио, форматируем как mm:ss
    const parts = duration.split(':');
    const minutes = parseInt(parts[0], 10);
    const seconds = parseInt(parts[1], 10);

    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  // Для других типов (например, видео), форматируем как hh:mm:ss
  const parts = duration.split(':');
  const minutes = parseInt(parts[1], 10);
  const seconds = parseInt(parts[2], 10);

  return `${minutes === 0 ? '0:' : minutes + ':'}${seconds < 10 ? '0' + seconds : seconds}`;
};

export const ProductDescription = ({
  tags,
  openDescription,
  setOpenDescription,
  type, // получаем тип (video, audio, image)
  audioData, // получаем данные для аудио/видео
  license, // Пропс для лицензии
  bitrate, // Пропс для Average Bitrate
  frameRate, // Пропс для Frame Rate
  usage, // Пропс для использования
  bitDepth, // Пропс для Bit Depth
  shootingLocation, // Пропс для места съемки
  modelRelease, // Пропс для релиза модели
  looping, // Пропс для зацикливания
  ownershipRelease, // Пропс для релиза собственности
  alphaMask, // Пропс для маски Alpha Matte
  alphaChannel, // Пропс для альфа-канала
  duration, // Пропс для продолжительности
}) => {
  const descriptionDetails = [
    ...(type === 'video' || type === 'audio'
      ? [{ label: 'Продолжительность', value: formatDuration(duration, type) }]
      : []),
    { label: 'Лицензия', value: license || 'Персональная лицензия ?' },
    { label: 'Average Bitrate', value: bitrate || '220 Mbps' },
    { label: 'Frame Rate', value: frameRate || '30.00 fps' },
    { label: 'Использование', value: usage || 'Коммерческий' },
    { label: 'Bit Depth', value: bitDepth || '24' },
    { label: 'Место съемки', value: shootingLocation || 'N/A' },
    { label: 'Релиз модели', value: modelRelease || 'Нет' },
    { label: 'Зацикливание', value: looping || 'Нет' },
    { label: 'Релиз собственности', value: ownershipRelease || 'Нет' },
    { label: 'Маска Alpha Matte', value: alphaMask || 'Да' },
    { label: 'Альфа-канал', value: alphaChannel || 'Нет' },
  ];

  return (
    <>
      <div
        className="h-[32px] items-center cursor-pointer hidden sm:flex sm:mt-8"
        onClick={() => setOpenDescription((open) => !open)}
      >
        <div className="mr-3 font-medium text-sm text-[#1C2024]">
          Описание и данные
        </div>
        <img src={ChevronDownIcon} alt="chevron-down" className="size-4" />
      </div>

      <hr className="w-full mt-4 border-solid -border border-[#F0F0F3] hidden sm:block text-[#1C2024]" />

      {openDescription && (
        <div className="flex flex-col">
          <div className="mt-4">
            <h3 className="font-medium text-[#1C2024]">Описание</h3>
            <div className="mt-4 max-w-[660px] text-[#1C2024]">
              {audioData.description}
            </div>
          </div>

          <hr className="w-full mt-6 border-solid -border border-[#F0F0F3] sm:hidden text-[#1C2024]" />

          <ul className="mt-6 grid grid-cols-3 sm:grid-cols-1 sm:mt-6">
            {descriptionDetails.map((detail) => (
              <li
                key={detail.label}
                className="flex items-center text-sm sm:nth-item text-[#1c2024]"
              >
                <div className="mr-[2px] font-medium">{detail.label}:</div>
                <div>{detail.value}</div>
              </li>
            ))}
          </ul>

          <Tags tags={tags} className="mt-8 sm:hidden" />
        </div>
      )}
    </>
  );
};
