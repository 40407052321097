import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  user: null, // Храним пользователя в state
  loading: false, // Добавляем состояние для отслеживания загрузки
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload; // Устанавливаем пользователя в state
      state.loading = false; // После установки пользователя меняем состояние загрузки на false
    },
    setLoading: (state) => {
      state.loading = true; // Устанавливаем состояние загрузки
    },
    logout: (state) => {
      state.user = null;
      state.loading = false; // При выходе меняем состояние загрузки
      Cookies.remove('token'); // Удаляем токен из cookies при выходе
      Cookies.remove('refreshToken'); // Удаляем refreshToken из cookies при выходе
    },
  },
});

export const { setUser, setLoading, logout } = authSlice.actions;
export default authSlice.reducer;
