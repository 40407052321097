import { useState } from 'react';
import { Card } from './Card';
import { cardsData, tabs } from './data';
import { SearchInput } from 'components/SearchInput';
import { Sidebar } from './Sidebar';
import { useWindowSize } from 'hooks/useWindowSize';
import { ActualArticlesSlider } from './ActualArticlesSlider';

import BottomPaginate from 'components/paginate/BottomPaginate';

export const Blog = () => {
  const [activeTab, setActiveTab] = useState('Котики');
  const { width } = useWindowSize();
  const isMobile = width < 1080;

  const actualCards = cardsData.filter((card) => card.cardTags.actual);
  const nonActualCards = cardsData.filter((card) => !card.cardTags.actual);

  return (
    <div className="container mx-auto px-5 flex flex-col overflow-hidden max-w-full">
      {isMobile && (
        <Sidebar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isMobile={isMobile}
        />
      )}
      <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} mt-10 gap-10`}>
        {!isMobile && (
          <Sidebar
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isMobile={isMobile}
          />
        )}
        <div className="flex-1 max-w-full overflow-hidden">
          {!isMobile && (
            <div className="flex justify-between items-center">
              <h1 className="text-4xl font-bold">Котики</h1>
              <SearchInput
                className="max-w-[403px]"
                placeholder="Поиск по ключевым словам"
              />
            </div>
          )}
          {!isMobile && actualCards.length > 0 && (
            <ActualArticlesSlider articles={actualCards} />
          )}
          <div className={`grid gap-x-10 gap-y-[30px] grid-cols-3  sm:grid-cols-1 lg:grid-cols-[auto_auto] lg:place-items-center justify-center `}>
            {nonActualCards.map((card) => (
              <Card key={card.id} card={card} />
            ))}
          </div>
        </div>

      </div>

      <div
  className={`mt-10 mb-16 flex justify-center w-full ${isMobile ? '' : 'transform translate-x-[155px]'}`}
>
  <BottomPaginate pageCount={1089} />
</div>

    </div>
  );
};
