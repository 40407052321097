import React from 'react';

import './line.css';

function Line() {
  return (
    <div className="line container-m">
      <div className="line__inner">
        <div className="line__item"></div>
        <div className="line__middle-item"></div>
        <div className="line__item"></div>
      </div>
    </div>
  );
}

export default Line;
