import { useSelector, useDispatch } from 'react-redux';
import {
  Filters,
  FiltersSidebar,
  VideoContentList,
  AudioContentList,
  ImageContentList,
} from 'components';
import BottomPaginate from 'components/paginate/BottomPaginate';
import Select from 'UI/select/Select';
import { useState, useEffect } from 'react';
import { useFetchSearchResultsQuery } from 'rtk/SearchApi';
import {
  setPage,
  setItemsContent,
  setTotalResults,
} from 'rtk/Slices/dataSlice';
import NoResults from 'components/filters/NoResults';
import { useNavigate } from 'react-router-dom';

export const SearchResults = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    searchText,
    typeContent,
    itemsPerPage,
    page,
    sortBy,
    filters,
    totalResults,
    itemsContent,
  } = useSelector((state) => state.data);
  const { similarImages, isUsed } = useSelector((state) => state.similarImages);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Запрашиваем данные с API
  const { data, isLoading, isError, refetch } = useFetchSearchResultsQuery({
    searchText,
    page,
    itemsPerPage,
    typeContent,
    sortBy,
    filters,
  });

  // Логирование и обработка данных
  useEffect(() => {
    const combinedItems =
      isUsed === true && similarImages?.length
        ? similarImages
        : data?.result?.items?.item || [];

    if (combinedItems.length !== itemsContent?.length) {
      dispatch(setItemsContent(combinedItems));
    }

    // Обновляем общее количество результатов
    if (data?.result?.totalResults !== totalResults) {
      dispatch(setTotalResults(data?.result?.totalResults));
    }
  }, [
    data,
    dispatch,
    totalResults,
    itemsContent?.length,
    similarImages,
    isUsed,
  ]);

  // Навигация при клике на элемент
  const handleItemClick = (item) => {
    navigate(`/product/${typeContent}/${item['imageid']}`);
  };

  // Обработка изменения страницы
  const handlePageChange = ({ selected }) => dispatch(setPage(selected + 1));

  // Определяем элементы для отображения
  const items =
    isUsed && similarImages?.length > 0
      ? similarImages
      : data?.result?.items?.item || [];
  const pageCount = Math.ceil((data?.result?.totalResults || 0) / itemsPerPage);

  // Рендеринг компонента контента в зависимости от fileType
  const renderContentList = () => {
    switch (typeContent) {
      case 'video':
        return <VideoContentList items={items} onItemClick={handleItemClick} />;
      case 'audio':
        return <AudioContentList items={items} onItemClick={handleItemClick} />;
      case 'image':
      default:
        return <ImageContentList items={items} onItemClick={handleItemClick} />;
    }
  };

  // Рендеринг статуса загрузки
  const renderStatus = () => {
    if (isLoading) return <div className="loader">Загрузка...</div>;
    if (isError)
      return (
        <div>
          <p>Ошибка загрузки данных. Попробуйте снова.</p>
          <button onClick={refetch}>Попробовать снова</button>
        </div>
      );
    if (items.length === 0) return <NoResults searchText={searchText} />;
    return null;
  };

  return (
    <div className="container mx-auto px-10 flex flex-col text-[#1c2024] scroll-smooth">
      <Filters setSidebarOpen={setSidebarOpen} />
      <div className="flex gap-[9px] mt-6">
        {sidebarOpen && <FiltersSidebar />}
        {renderStatus() || renderContentList()}
      </div>
      <div className="bottom">
        <BottomPaginate
          currentPage={page}
          pageCount={pageCount}
          onPageChange={handlePageChange}
        />
        <Select value={itemsPerPage}>Количество на странице:</Select>
      </div>
    </div>
  );
};
