import React from 'react';
import { SearchInput } from 'components/SearchInput';
import ChevronDown from 'assets/icons/chevron-down.svg';
import { SortingByOptions } from 'components/filters/sorting';
import { Button } from '../UI/button';
import Setting from 'assets/icons/settings.svg';
import { ImageItem } from './cards/item';
import CartSVG from 'assets/icons/shoping-cart.svg';
import {Title} from '../UI/title';

import ShareSVG from 'assets/icons/share.svg';

export function Lastscreen() {
  return (
    <div className="max-w-full">
      <Title className='hidden md:block mb-8'>Недавно просмотренные</Title>
      <div className="flex flex-row justify-between -mt-[2px] h-12 lg:h-28  lg:flex-wrap gap-y-3 md:h-12 md:flex-row-reverse">
      <div className="flex md:flex-row-reverse gap-3 max-w-[880px] w-full">
  <Button className="px-[29px] md:hidden" text={'Фильтры'} />

  <Button className="hidden md:block md:p-3 p-[10px]" src={Setting} />
  
  <Button className="px-4 mr-[6px] md:hidden" textClassName="w-[110px] text-left" text="Видео: 8274" src={ChevronDown} color="white" />
  <Button className="px-4 md:hidden" textClassName="w-[110px] text-left" text="Действия" src={ChevronDown} color="white" />
  
  <SearchInput
    placeholder="Поиск по ID или ключевым словам"
    classNameInput="w-[403px] md:text-xs"
    className="w-full"
  />
</div>

        <div className="flex min-w-[400px] md:w-full  ">
        <SortingByOptions
          sortedByOptions={['ID', 'Дата создания', 'Изменения']}
          className='mr-[30px] md:hidden'
        />
        <Button
          textClassName={'text-sm'}
          className="gap-1 py-[14px] px-6 mr-3 md:hidden"
          text={'В корзину'}
          src={CartSVG}
          iconClassName='color-[#fff]'
        />
        <Button className='bg-[#C1D0FF] md:hidden p-[14px]' src={ShareSVG} iconClassName='text-[#1C2024]' />
        </div>
      </div>
      <ImageGallery />
    </div>
  );
}

const mockData = [
  {
    id: 1,
    title: 'Mountain Landscape',
    description: 'A beautiful view of the mountains during sunset.',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 2,
    title: 'City Skyline',
    description: 'A bustling city skyline at night with lights shining.',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 3,
    title: 'Beach Sunset',
    description: 'Relaxing sunset at the beach with palm trees.',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 4,
    title: 'Forest Path',
    description: 'A peaceful path in the forest surrounded by trees.',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
];
const generateItems = (data, count = 20) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push({ ...data[i % data.length], id: i + 1 }); // Дублируем элементы
  }
  return result;
};

export function ImageGallery() {
  const items = generateItems(mockData); // Генерируем 20 карточек

  return (
    <ul className="grid grid-cols-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[6px] mt-10 md:mt-8">
      {items.map((item) => (
        <ImageItem key={item.id} item={item} />
      ))}
    </ul>
  );
}
