import React from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { MobilePreview } from './MobilePreview';
import { DesktopPreview } from './DesktopPreview';

export function Preview() {
  const { width } = useWindowSize();

  if (width >= 1345) {
    return <DesktopPreview />;
  } else {
    return <MobilePreview />;
  }
}
