import React from 'react';
import { cards } from './data';
import { SearchInput } from 'components/SearchInput';
import SVG from 'react-inlinesvg';
import SettingIcon from 'assets/icons/settings.svg';
import ArrowTopRightSVG from 'assets/icons/arrows/arrow-top-right.svg';
import PreviewSVG from 'assets/icons/Preview.svg';

export function MobilePreview() {
  return (
    <div className="w-full flex gap-8 flex-col">
      <h1 className="text-[#1C2024] text-[28px]"> История покупок</h1>
      <div className="flex gap-2 w-full">
        <SearchInput placeholder="Запрос... |" className="flex-1" />
        <div className="bg-[#1F2D5C] rounded-lg p-3">
          <SVG src={SettingIcon} className="h-6 w-6" />
        </div>
      </div>

      <div className="w-full flex gap-8 flex-row flex-wrap">
        {cards.map((card) => (
          <div
            className="flex w-[48%] lg:w-full items-center flex-col gap-4 bg-white p-5 rounded-xl"
            key={card.id}
          >
            {/* Image Section */}
            <div className="flex gap-4 w-full">
              <div className="w-[40%] relative">
                <img
                  src={card.imageUrl}
                  alt="alt"
                  className="w-full object-cover h-auto rounded-lg"
                />
                <div className="absolute bottom-2 left-2 flex gap-2">
                  <img
                    src={PreviewSVG}
                    alt="Play"
                    className="w-5 h-5 cursor-pointer"
                  />
                </div>
              </div>

              {/* Card Details */}
              <div className="w-[60%] flex flex-col gap-1">
                <p className="text-[#1C2024] font-medium text-sm">
                  {card.title}
                </p>
                <div className="text-[#80838D] text-sm">
                  <p>Видео &#8226; ID {card.videoId}</p>
                  <p>{card.licenseType}</p>
                  <p>
                    {card.resolution} &#8226; {card.format}
                  </p>
                  <div className="text-2xl font-semibold text-[#1C2024]">
                    <p>{card.price}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* License Information */}
            <div className="w-full flex flex-col gap-8 mt-5 items-start text-sm text-[#1C2024]">
              <div className="flex gap-10 flex-row">
                <div>
                  <p className="mb-3 text-[#1C2024]">Дата запроса</p>
                  <p className="text-[#1C2024] font-medium">
                    {card.licenseDetails}
                  </p>
                  <p className="text-[#1C2024] font-medium">
                    {' '}
                    {card.licenseTime}
                  </p>
                </div>
                <div className="mb-6 flex gap-1 flex-col">
                  <p className="text-[#1C2024] flex text-sm items-center gap-2">
                    Статус запроса
                  </p>

                  <p
                    className={`font-bold text-lg ${card.Status === 'Одобрен' ? 'text-[#30A46C]' : 'text-[#E5484D]'}`}
                  >
                    {card.Status}
                  </p>
                </div>
              </div>
              <div className="text-sm text-[#1F2D5C] flex flex-col gap-6 font-bold">
                <p className="underline ">{card.materialLink}</p>
                <p className="underline">{card.licenseLink}</p>
                <p className="text-[#1F2D5C] underline font-bold flex flex-row items-end">
                  Поделиться <SVG src={ArrowTopRightSVG} />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
