import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  similarImages: [],
  isUsed: false,
  fileType: 'image', // новое поле для хранения типа файла
  searchType: '', // новое поле для хранения searchType
};

const similarImagesSlice = createSlice({
  name: 'similarImages',
  initialState,
  reducers: {
    setSimilarImages(state, action) {
      state.similarImages = [...action.payload];
      state.isUsed = true; // устанавливаем isUsed в true, когда добавлены похожие изображения
    },
    setFileType(state, action) {
      state.fileType = action.payload; // устанавливаем только fileType
    },
    addSimilarImages(state, action) {
      state.similarImages = [...state.similarImages, ...action.payload];
    },
    clearSimilarImages(state) {
      state.similarImages = [];
      state.isUsed = false; // сбрасываем isUsed при очистке данных
      state.fileType = 'image'; // очищаем тип при удалении
    },
    setSearchType(state, action) {
      state.searchType = action.payload; // устанавливаем значение searchType
    },
    setIsUsed(state) {
      state.isUsed = !state.isUsed;
    },
  },
});

export const getFileType = (state) => state.similarImages.fileType;
export const getSearchType = (state) => state.similarImages.searchType; // добавляем геттер для searchType

export const {
  setSimilarImages,
  addSimilarImages,
  clearSimilarImages,
  setFileType,
  setIsUsed,
  setSearchType, // экспортируем action для установки searchType
} = similarImagesSlice.actions;

export default similarImagesSlice.reducer;
