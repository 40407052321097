import React, { useState } from 'react';
import { Dropdown } from '../dropdown';

import WindowsIcon from 'assets/icons/windows.svg';
import classnames from 'classnames';

import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Limits } from './limits';
import { Subs } from './subs';
import {GroupSubAcc} from './GroupSubAcc'

export const AdminSection = () => {
  const [activeItem, setActiveItem] = useState(null);

  const items = [
    { value: 'first-item', title: 'Мои подписки', component: <Subs />, underline: true },
    { value: 'second-item', title: 'Управление лимитами', component: <Limits />, underline: false },
    { value: 'third-item', title: 'Группы субаккаунтов', component: <GroupSubAcc />, underline: false },
  ];

  const handleToggle = (value) => {
    setActiveItem(activeItem === value ? null : value);
  };

  return (
    <div className="w-full flex flex-col">
      <Dropdown
        url={WindowsIcon}
        label="Администрирование"
        className="border rounded-xl md:flex"
        iconClassName="rotate-90"
      />

      <div className=" max-w-full mt-3 ">
        {items.map((item, index) => (
          <div key={item.value}>
            {/* Разделительная полоса между элементами */}
            {index !== 0&& (
              <div className="border-t border-solid my-8 border-[#E8E8EC] "></div>
            )}

            <div
              className={classnames(
                "border-b border-[#E0E0E0]",
                { "border-b-[1px] border-[#E8E8EC]": item?.underline }
              )}
            >
              <div
                onClick={() => handleToggle(item.value)}
                className={classnames(
                  "flex flex-row items-center gap-2 xl:p-0 cursor-pointer",
                  { "border-b-[1px] border-[#E8E8EC]": item?.underline }
                )}
              >
                <div className='flex flex-row items-center'>

                <div className="font-bold text-[#1F2D5C] md:text-[28px] px-1 text-4xl w-full">
                  {item.title}
                </div>

                <ChevronDownIcon
                  className={classnames(
                    'w-8 h-8 transition-transform duration-300 rotate-0 text-[#1F2D5C]',
                    { '!-rotate-180': activeItem === item.value },
                    'ml-[8px]' // Отступ между текстом и стрелкой
                  )}
                  />
                  </div>
              </div>

              {activeItem === item.value && (
                <div className="p-5 xl:p-0 text-sm text-gray-600">{item.component}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};





