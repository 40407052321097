import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { setSearchText, setTypeContent } from 'rtk/Slices/dataSlice';
import { setIsUsed } from 'rtk/Slices/similarImagesSlice';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';
import { Button } from '@chakra-ui/button';
import { SearchInput } from 'components/SearchInput'; // Импортируем переиспользуемый компонент

import SeparatorIcon from 'assets/icons/separator.svg';
import ChevronDownIcon from 'assets/icons/chevron-down.svg';

const CategorySelector = ({
  categories,
  selectedCategory,
  onCategorySelect,
}) => (
  <Menu>
    <MenuButton
      as={Button}
      variant="ghost"
      rightIcon={<img src={ChevronDownIcon} alt="chevron-down" />}
      aria-label="Выбор категории"
    >
      {selectedCategory}
    </MenuButton>
    <MenuList className=" relative top-2 -left-6 w-[175px] py-2 flex flex-col bg-white rounded-xl text-xs leading-4 text-[#1C2024] sm:-left-3 z-[1050]">
      {categories
        .filter((category) => category.label !== selectedCategory)
        .map((category) => (
          <MenuItem
            key={category.type}
            onClick={() => onCategorySelect(category.type)}
            className="py-2 pl-4 hover:bg-gray-100"
          >
            {category.label}
          </MenuItem>
        ))}
    </MenuList>
  </Menu>
);

export const SearchBar = ({ className, categories }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { typeContent, searchText } = useSelector((state) => state.data);
  const [searchTextState, setSearchTextState] = useState(searchText);

  const handleCategorySelect = (category) => {
    dispatch(setTypeContent(category));
  };

  const performSearch = useCallback(() => {
    dispatch(setSearchText(searchTextState));
    dispatch(setIsUsed(false));
    if (window.location.pathname !== '/search-results') {
      navigate('/search-results');
    }
  }, [dispatch, navigate, searchTextState]);

  const handleInputChange = (e) => {
    setSearchTextState(e.target.value);
    dispatch(setIsUsed(false));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') performSearch();
  };

  const selectedCategoryLabel =
    categories.find((category) => category.type === typeContent)?.label ||
    'Видео';

  return (
    <div
      className={classnames(
        'flex items-center rounded-lg h-[48px] pl-6 pr-4 p-2 bg-white w-full flex-1 sm:px-3 sm:text-xs',
        className
      )}
    >
      <CategorySelector
        categories={categories}
        selectedCategory={selectedCategoryLabel}
        onCategorySelect={handleCategorySelect}
      />
      <img src={SeparatorIcon} alt="separator" className="mx-3" />
      <SearchInput
        value={searchTextState}
        onChange={handleInputChange}
        onSearch={performSearch}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
