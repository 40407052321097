import { useState } from "react";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import classNames from "classnames";

import SVG from 'react-inlinesvg'

import ChevronDownIcon from 'assets/icons/chevron-down.svg'



export const Sidebar = ({ tabs, activeTab, setActiveTab, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderMobileSidebar = () => (
    <div className="relative max-w-full z-10 mt-8">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex justify-between items-center p-5 bg-white rounded-xl border border-[#F0F0F3] text-[#1C2024]  text-sm font-medium"
      >
        {tabs.find((tab) => tab.id === activeTab)?.name || "Выберите вкладку"}
        <SVG  src={ChevronDownIcon} className="h-[24px] w-[24px] text-[#1C2024]" />
      </button>
      {isOpen && (
        <div className="absolute left-0 w-full mt-2 bg-white  rounded-md">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => {
                setActiveTab(tab.id);
                setIsOpen(false);
              }}
              className={classNames(
                "w-full text-left p-2 block",
                activeTab === tab.id
                  ? "bg-[#EDF2FE] text-[#202020]"
                  : "hover:bg-[#F0F4FF] text-[#60646C]"
              )}
            >
              {tab.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );

  const renderDesktopSidebar = () => (
    <nav className="w-[310px] bg-white rounded-2xl p-[10px] space-y-2 h-full">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => setActiveTab(tab.id)}
          className={classNames(
            "w-full p-2 flex items-center justify-between rounded-md text-left",
            activeTab === tab.id
              ? "bg-[#EDF2FE] text-[#202020]"
              : "hover:bg-[#F0F4FF] text-[#60646C]"
          )}
          aria-current={activeTab === tab.id ? "true" : undefined}
        >
          {tab.name}
          {activeTab === tab.id && (
            <ChevronRightIcon className="h-4 w-4 text-[#60646C]" />
          )}
        </button>
      ))}
    </nav>
  );

  return isMobile ? renderMobileSidebar() : renderDesktopSidebar();
};
