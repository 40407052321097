export const filterLabels = {
  contributorname: 'По автору',
  purchase_date_from: 'Дата покупки (от)',
  purchase_date_to: 'Дата покупки (до)',
  price_from: 'Цена (от)',
  price_to: 'Цена (до)',
  subscription: 'Подписка',
  single_purchase: 'Разовая покупка',
  commercial: 'Коммерческая лицензия',
  editorial: 'Редакционная лицензия',
  exclude_ai: 'Исключить созданные ИИ',
  only_ai: 'Только ИИ контент',
  editorChoice: 'Выбор редактора',
  modelAge: 'Возраст модели',
  ethnicity: 'Этническая принадлежность',
  color: 'Цветность',
  orientation: 'Ориентация',
  people: 'Наличие людей',
  gender: 'Пол',
  models: 'Количество людей',
  level: 'Уровень',
};

export const editorChoiceOptions = [
  { key: '1', label: 'Выбор редактора' },
  { key: '0', label: 'Не выбор редактора' },
];

export const peopleOptions = [
  { key: '0', label: 'Нет людей' },
  { key: '1', label: 'С людьми' },
  { key: 'all', label: 'Все' },
];
export const genderOptions = [
  { key: 'male', label: 'Мужчина' },
  { key: 'female', label: 'Женщина' },
  { key: 'all', label: 'И мужчины, и женщины' },
];
export const modelsOptions = [
  { key: '1', label: 'Один человек' },
  { key: '2', label: 'Два человека' },
  { key: '3', label: 'Три человека' },
  { key: '4', label: 'Четыре человека' },
  { key: '5', label: 'Группа людей' },
];
export const levelOptions = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
];

export const modelAgeOptions = [
  { key: '1', label: 'Младенец' },
  { key: '2', label: 'Ребенок' },
  { key: '3', label: 'Дошкольник' },
  { key: '4', label: 'Подросток' },
  { key: '5', label: 'Молодой человек' },
  { key: '6', label: 'Взрослый' },
  { key: '7', label: 'Зрелый' },
  { key: '8', label: 'Средний возраст' },
  { key: '9', label: 'Пожилой' },
];

export const ethnicityOptions = [
  { key: '1', label: 'Азиат' },
  { key: '2', label: 'Чернокожий' },
  { key: '3', label: 'Кавказец' },
  { key: '4', label: 'Латинец' },
  { key: '5', label: 'Многорасовые' },
  { key: '6', label: 'Другие' },
];

export const colorOptions = [
  { key: '1', label: 'Цветное' },
  { key: '0', label: 'Черно-белое' },
];

export const orientationOptions = [
  { key: 'portrait', label: 'Портрет' },
  { key: 'landscape', label: 'Пейзаж' },
  { key: 'square', label: 'Квадратное' },
  { key: 'panorama', label: 'Панорама' },
];
