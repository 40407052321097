import React from 'react';
import PropTypes from 'prop-types';
import Sprite from '../sprite/Sprite';
import Dropdown from 'rc-dropdown';
import Item from './Item';

import './select.css';

function Select({ options = [], value, onChange, children }) {
  const dropdownList = (
    <ul className="select__list">
      {options
        .filter((item) => item !== value)
        .map((item) => (
          <Item key={item} item={item} setSelected={onChange} />
        ))}
    </ul>
  );

  return (
    <div className="select">
      <p className="select__title">{children}</p>
      <div className="select__wrapper">
        <Dropdown overlay={dropdownList} trigger={['click']}>
          <button className="select__button" type="button">
            <span>{value}</span>
            <Sprite icon="arrow-bottom" width={16} height={16} />
          </button>
        </Dropdown>
      </div>
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default Select;
