import ChevronRightIcon from 'assets/icons/chevron-right.svg';

const TrackInfo = ({ title, genre, author, onItemClick, item }) => (
  <div className="ml-8 flex flex-col sm:ml-5">
    <div
      className="font-medium text-sm text-[#1C2024] cursor-pointer"
      onClick={() => onItemClick(item)}
    >
      {title}
    </div>
    <div className="flex items-center gap-1 mt-1 text-sm text-[#80838D] ">
      <div>{genre}</div>
      {/* <span>&#8226;</span>
      <div>Автор: {author}</div> */}
    </div>
    <div
      onClick={() => onItemClick(item)}
      className="mt-4 flex items-center font-medium text-sm text-[#1C2024] sm:block cursor-pointer"
    >
      <div>Подробнее о треке</div>
      <img src={ChevronRightIcon} alt="chevron-right-icon" className="size-4" />
    </div>
  </div>
);

export default TrackInfo;
