export const users = [
  {
    id: 1,
    name: 'Константин Иванов',
    role: 'Администратор',
    avatarLetter: 'A',
    subscriptions: [
      'Видео годовая (700 материалов)',
      'Подписка (50 материалов / месяц)',
      'Подписка (100 000 ₽)',
      'Подписка (15 000 ₽)',
    ],
  },
  {
    id: 2,
    name: 'Анна Смирнова',
    role: 'Пользователь',
    avatarLetter: 'A',
    subscriptions: [
      'Фото месячная (500 материалов)',
      'Подписка (30 материалов / месяц)',
    ],
  },
  {
    id: 3,
    name: 'Игорь Петров',
    role: 'Пользователь',
    avatarLetter: 'И',
    subscriptions: ['Базовая (100 материалов)'],
  },
];

export const subscriptions = [
  {
    id: 1,
    name: 'Название плана подписки 1',
    totalMaterials: 7400,
    photos: { available: 249, total: 1000 },
    videos: { available: 627, total: 6000 },
    music: { available: 0, total: 400 },
    contractNumber: '№6764389',
    validityPeriod: '01.01.2024 — 31.12.2024',
    status: 'Активен',
    price: '90 000 ₽',
  },
  {
    id: 2,
    name: 'Название плана подписки 2',
    totalMaterials: 5200,
    photos: { available: 150, total: 800 },
    videos: { available: 500, total: 5000 },
    music: { available: 20, total: 300 },
    contractNumber: '№8765432',
    validityPeriod: '01.06.2024 — 31.05.2025',
    status: 'Активен',
    price: '75 000 ₽',
  },
  {
    id: 3,
    name: 'Название плана подписки 3',
    totalMaterials: 6000,
    photos: { available: 300, total: 1200 },
    videos: { available: 700, total: 7000 },
    music: { available: 10, total: 500 },
    contractNumber: '№9876543',
    validityPeriod: '01.03.2024 — 28.02.2025',
    status: 'Активен',
    price: '80 000 ₽',
  },
];

export const groups = [
  {
    id: 1,
    name: 'Команда дизайнеров',
    avatarLetter: 'D',
    role: 'Дизайнер',
    person: '18 пользователей',
  },
  {
    id: 2,
    name: 'Команда разработчиков',
    avatarLetter: 'R',
    role: 'Разработчик',
    person: '36 пользователей',
  },
  {
    id: 3,
    name: 'Менеджеры',
    avatarLetter: 'M',
    role: 'Менеджер',
    person: '6 пользователей',
  },
];
