import { Link } from 'react-router-dom';
import { CardTags } from './CardTags';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import PlayBlog from 'assets/icons/PlayBlog.svg';
import ShareBlog from 'assets/icons/shareBlog.svg';

export const Card = ({ card }) => {
  return (
    <div className="flex gap-2 border rounded-lg overflow-hidden bg-white flex-col relative transition-all p-5 h-[392px] max-w-[310px]">
      <div className="relative w-full h-48">
        <img
          src={card.image}
          alt={card.title}
          className="w-full  object-cover rounded-lg h-[180px]"
        />
                      <div className="absolute top-3 left-3  bg-[#C1D0FF] text-xs font-medium  px-[8px] rounded-[4px]   text-[#1F2D5C] py-[5.5px]">
                Котики
              </div>
        {/* Иконки Play и Share в левом нижнем углу */}
        <div className="absolute bottom-2 left-2 flex gap-2">
          <img src={ShareBlog} alt="Share" className="w-6 h-6 cursor-pointer hover:opacity-100 transition-opacity" />
          <img src={PlayBlog} alt="Play" className="w-6 h-6 cursor-pointer hover:opacity-100 transition-opacity" />
        </div>
      </div>

      <div className="flex flex-col flex-grow">
        <CardTags
          readingTime={card.cardTags.readingTime}
          views={card.cardTags.views}
          actual={card.cardTags.actual}
        />
        <h2 className="text-md mb-2 mt-2 text-gray-900 line-clamp-2 h-12 font-bold ">
          {card.title}
        </h2>

        {/* Автор и дата */}
        <div className="mt-2 flex items-center text-[#80838D] text-sm flex-wrap min-w-0 justify-between">
          <div className="flex items-center ml-1 min-w-0">
            <div>Автор:</div>
            <div className="ml-[2px] underline text-[#3E63DD] truncate max-w-[120px]">
              {card.author}
            </div>
          </div>
          <span className="mx-1">&bull;</span>
          <div className="truncate max-w-[80px]">{card.date}</div>
        </div>

        <Link
          to={`/blog/${card.id}`}
          className="text-[#3358D4] cursor-pointer mt-auto flex gap-1 items-center"
        >
          Читать полностью
          <ChevronRightIcon className="text-[#3358D4] w-4 h-4" />
        </Link>
      </div>
    </div>
  );
};
