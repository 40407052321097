import React, { useState } from 'react';
import { subscriptions } from './data';
import { useWindowSize } from 'hooks/useWindowSize';

export const Subs = () => {
  const [activeTab, setActiveTab] = useState('active');
  const { width } = useWindowSize();
  const isMobile = width <= 1023;

  return (
    <div>
      {/* Табы */}
      <div className="flex mt-8">
        <div
          className={`w-full h-10 flex justify-center items-center cursor-pointer text-base leading-6 font-medium ${
            activeTab === 'active'
              ? 'border-b-2 border-solid border-[#1F2D5C] text-[#1C2024]'
              : 'text-[#80838D] border-b border-solid border-[#F0F0F3]'
          }`}
          onClick={() => setActiveTab('active')}
        >
          Активные подписки
        </div>
        <div
          className={`w-full h-10 flex justify-center items-center cursor-pointer text-base leading-6 font-medium ${
            activeTab === 'archive'
              ? 'border-b-2 border-[#1F2D5C] border-solid text-[#1C2024]'
              : 'text-[#80838D] border-b border-solid border-[#F0F0F3]'
          }`}
          onClick={() => setActiveTab('archive')}
        >
          Архивные подписки
        </div>
      </div>

      {/* Контент для активных подписок */}
      {activeTab === 'active' && (
        <div className="w-full flex flex-col mt-10 gap-6 md:max-w-full md:mt-8">
          {subscriptions.map((sub) => (
            <div
              key={sub.id}
              className="p-6 flex flex-row lg:flex-col gap-[10px] bg-[#FDFDFE]"
            >
              <div className="w-full border-r border-solid border-[#F0F0F3] lg:order-2">
                <p className="font-bold -ml-[5px] text-2xl md:text-xl text-[#1C2024] w-full">
                  {sub.name}
                </p>
                <div className="flex flex-col lg:order-1 gap-2 mb-2 text-[#1C2024]">
                  <p className="text-lg md:text-base">
                    {sub.totalMaterials} материалов на период действия договора
                  </p>
                  <p>
                    Фотографии &#8226; Доступно{' '}
                    <span className="underline font-medium text-[#1C2024]">
                      {sub.photos.available}
                    </span>{' '}
                    из {sub.photos.total}
                  </p>
                  <p>
                    Видео &#8226; Доступно{' '}
                    <span className="underline font-medium text-[#1C2024]">
                      {sub.videos.available}
                    </span>{' '}
                    из {sub.videos.total}
                  </p>
                  <p>
                    Музыка &#8226; Доступно{' '}
                    <span className="underline font-medium text-[#1C2024]">
                      {sub.music.available}
                    </span>{' '}
                    из {sub.music.total}
                  </p>
                </div>
                <div className="border-t border-solid border-[#F0F0F3] mb-4" />
                {!isMobile && (
                  <div className="bg-[#EDF2FE] mr-6 rounded-lg text-[#1F2D5C] font-medium p-3 flex text-center justify-center">
                    Отменить подписку
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-2 min-w-[230px] lg:order-3">
                <p className="text-[#1C2024] text-xl md:text-base">
                  Договор {sub.contractNumber}
                </p>
                <p className="text-lg md:text-xs text-[#1C2024]">
                  Срок действия:
                </p>
                <p className="text-[#5f646C]">{sub.validityPeriod}</p>
                <p className="text-[#218358] md:text-xs">{sub.status}</p>
                <div className="flex flex-row items-end">
                  <p className="font-bold text-xl md:text-2xl text-[#1C2024]">
                    {sub.price}
                  </p>
                  <p>/ год</p>
                </div>
                {isMobile && (
                  <>
                    <div className="border-t border-solid border-[#F0F0F3] mb-2" />
                    <div className="bg-[#EDF2FE] mr-6 rounded-lg text-[#1F2D5C] font-medium p-3 flex text-center justify-center">
                      Отменить подписку
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Контент для архивных подписок */}
      {activeTab === 'archive' && (
        <div className="w-full flex flex-col mt-10 gap-6 md:max-w-full md:mt-8">
          {subscriptions.map((sub) => (
            <div
              key={sub.id}
              className="p-6 flex flex-row lg:flex-col gap-[10px] bg-[#FDFDFE]"
            >
              <div className="w-full border-r border-solid border-[#F0F0F3] lg:order-2">
                <p className="font-bold -ml-[5px] text-2xl md:text-xl opacity-60 text-[#1C2024] w-full">
                  {sub.name}
                </p>
                <div className="flex flex-col gap-2 mb-2 text-[#1C2024] opacity-60">
                  <p className="text-lg md:text-base">
                    {sub.totalMaterials} материалов на период действия договора
                  </p>
                  <p>
                    Фотографии &#8226; Доступно{' '}
                    <span className="underline font-medium text-[#1C2024]">
                      0
                    </span>{' '}
                    из {sub.photos.total}
                  </p>
                  <p>
                    Видео &#8226; Доступно{' '}
                    <span className="underline font-medium text-[#1C2024]">
                      0
                    </span>{' '}
                    из {sub.videos.total}
                  </p>
                  <p>
                    Музыка &#8226; Доступно{' '}
                    <span className="underline font-medium text-[#1C2024]">
                      0
                    </span>{' '}
                    из {sub.music.total}
                  </p>
                </div>
                <div className="border-t border-solid border-[#F0F0F3] mb-4" />
                {!isMobile && (
                  <div className="mt-3 bg-[#1F2D5C] mr-6 rounded-lg text-[#FFFFFF] font-medium p-3 flex text-center justify-center">
                    Возобновить подписку
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-2 min-w-[230px] lg:order-3">
                <p className="text-[#1C2024] text-xl md:text-base">
                  Договор {sub.contractNumber}
                </p>
                <p className="text-lg md:text-xs text-[#1C2024]">
                  Срок действия:
                </p>
                <p className="text-[#5f646C]">{sub.validityPeriod}</p>
                <p className="text-[#CE2C31] md:text-xs">Истек</p>
                <div className="flex flex-row items-end">
                  <p className="font-bold text-xl md:text-2xl text-[#1C2024]">
                    {sub.price}
                  </p>
                  <p>/ год</p>
                </div>
                {isMobile && (
                  <>
                    <div className="border-t border-solid border-[#F0F0F3] mb-2" />
                    <div className="mt-3 bg-[#1F2D5C] mr-6 rounded-lg text-[#FFFFFF] font-medium p-3 flex text-center justify-center">
                      Возобновить подписку
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
