import classnames from "classnames";
import { useState } from "react";

export const ImageItem = ({ item, className }) => {
  const [isHovered, setIsHovered] = useState(false);


  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  // Разбиваем изображение на 4 части
  const renderSubImages = () => {
    const subImageStyle = {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    };

    return (
      <>
        <div className="w-full h-full overflow-hidden">
          <img src={item.imageUrl} alt={`${item.title} part 1`} style={subImageStyle} />
        </div>
        <div className="w-full h-full overflow-hidden">
          <img src={item.imageUrl} alt={`${item.title} part 2`} style={subImageStyle} />
        </div>
        <div className="w-full h-full overflow-hidden">
          <img src={item.imageUrl} alt={`${item.title} part 3`} style={subImageStyle} />
        </div>
        <div className="w-full h-full overflow-hidden">
          <img src={item.imageUrl} alt={`${item.title} part 4`} style={subImageStyle} />
        </div>
      </>
    );
  };

  return (
    <>
      <li
        className={classnames(
          "relative group w-full h-[300px] transform transition-transform duration-200 ease-in-out list-none md:mb-20",
          className
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Контейнер для 4 частей */}
        <div className="grid grid-cols-2 grid-rows-2 gap-0 h-full w-full rounded-xl overflow-hidden">
          {renderSubImages()}
        </div>

        {/* Внешний контейнер с закруглением */}
        <div className="absolute top-0 left-0 right-0 bottom-0 rounded-xl border-4 border-transparent pointer-events-none"></div>

        {/* Остальные элементы */}
        <div className="absolute top-0 left-0 opacity-100 h-full w-full flex flex-col justify-between items-stretch rounded-xl sm:visible sm:opacity-100">
          <div className="h-[70px] w-full mb-auto rounded-t-xl bg-gradient-to-b from-[#272727]/90 from-10"></div>
        </div>

        {/* Оверлей с текстом */}
        <div className="absolute left-0 items-start top-0 opacity-100 w-full flex justify-between sm:relative z-[1000]">
          <div className="flex flex-col justify-between flex-grow z-[1001] p-2 rounded-xl">
            <p className="text-sm text-[#FCFCFD] z-[1002] md:text-[#1C2024] md:font-medium md:text-lg md:mb-2">
              {item.title}
            </p>
            <p className="text-xs text-[#FCFCFD]/70 mt-auto z-[1002] md:text-[#80838D] md:text-xs">
              38470 файла
            </p>
          </div>
        </div>

        {/* Круглая кнопка в левом нижнем углу */}

      </li>

    </>
  );
};
