import { useState } from 'react';
import classnames from 'classnames';
import { SortedSelect } from '../filters/sorted-select';
import ChevronLeftIcon from '../../assets/icons/chevron-left.svg';
import ChevronRightIcon from '../../assets/icons/chevron-right.svg';
import { useFetchSearchResultsQuery } from '../../rtk/SearchApi';

const itemsAmountOptions = [48, 64, 96, 120];

export const Pagination = ({ searchText, typeContent, sortBy }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(48);

  const { data, isLoading } = useFetchSearchResultsQuery({
    searchText,
    page: currentPage,
    itemsPerPage,
    typeContent,
    sortBy,
  });

  const handlePageChange = (page) => {
    if (page < 1 || page > data?.totalPages) return;
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="relative mt-10 mb-16 w-full h-12 flex justify-center items-center sm:my-12">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage <= 1}
        className="mr-4 cursor-pointer"
      >
        <img src={ChevronLeftIcon} alt="chevron-icon" />
      </button>

      <div className="flex justify-center items-center gap-3">
        {[...Array(data?.totalPages)].map((_, index) => (
          <PaginationButton
            key={index}
            value={index + 1}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
        ))}
      </div>

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage >= data?.totalPages}
        className="ml-4 cursor-pointer"
      >
        <img src={ChevronRightIcon} alt="chevron-icon" />
      </button>
      <div className="absolute right-0 top-[50%] -translate-y-1/2 flex items-center font-medium text-sm md:hidden">
        <p>Количество на странице:</p>

        <SortedSelect
          options={itemsAmountOptions}
          selectedValue={itemsPerPage}
          onChange={handleItemsPerPageChange}
        />
      </div>
    </div>
  );
};

const PaginationButton = ({ value, currentPage, setCurrentPage }) => (
  <button
    onClick={() => setCurrentPage(value)}
    className={classnames(
      'h-12 min-w-12 px-[11px] rounded-lg font-medium text-base transition-all duration-300 hover:bg-opacity-70 active:bg-white active:text-[#1F2D5C]',
      {
        'bg-[#1F2D5C] text-white': currentPage === value,
        'border-solid border-[#B9BBC6] border-[1px] text-[#1F2D5C]':
          currentPage !== value,
      }
    )}
  >
    {value}
  </button>
);
