import classnames from 'classnames';
import ReactPlayer from 'react-player';
import { useState, useRef } from 'react';

export const VideoItem = ({ item, className }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);

  const handleMouseEnter = () => {
    setIsPlaying(true);
  };

  const handleMouseLeave = () => {
    setIsPlaying(false);
  };

  return (
    <li
      className={classnames(
        'relative group h-full w-full rounded-xl transform transition-transform duration-200 ease-in-out list-none',
        className
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="h-full w-full">
        <ReactPlayer
          ref={playerRef}
          url={item.url}
          muted={true}
          loop={true}
          className="react-player"
          seekTo={0}
          width="100%"
          height="100%"
          playing={isPlaying}
        />
      </div>
    </li>
  );
};
