import { Header } from './header';
import { Footer } from '../footer/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import { contentNavigationData } from '../../data';
import SVG from 'react-inlinesvg';
import { Breadcrumbs } from './Breadcrumbs'; // <-- Импортируем компонент

export const Layout = () => {
  const location = useLocation();

  const isNavigationPages = [
    '/author',
    '/profile',
    '/subscription',
    '/shopping-cart',
    '/blog',
  ];

  const shouldShowNavigation = isNavigationPages.some((page) =>
    location.pathname.startsWith(page)
  );

  return (
    <div className="min-h-screen flex flex-col">
      <Header />

      {/* Хлебные крошки */}

      {/* Навигация */}
      {shouldShowNavigation && (
        <>
          <ul className="relative left-1/2 -translate-x-1/2 w-full h-10 flex justify-center items-center gap-[58px] bg-[#F0F0F3] md:hidden">
            {contentNavigationData.map((item, index) => (
              <li
                className="flex gap-[10.5px] font-medium text-[#1C2024] cursor-pointer items-center"
                key={index}
              >
                <SVG
                  src={item.url}
                  alt="content-icon"
                  className="align-center"
                />
                <div>{item.label}</div>
              </li>
            ))}
          </ul>

          <div className="container mx-auto px-10 sm:px-5">
            <Breadcrumbs />
          </div>
        </>
      )}
      <Outlet />
      <Footer />
    </div>
  );
};
