import classnames from 'classnames';
import { useState } from 'react';
import { OverlayButton } from 'components/overlay-button';

import HeartIcon from 'assets/icons/heart.svg';
import ShareIcon from 'assets/icons/share.svg';
import DisplayIcon from 'assets/icons/display.svg';
import BookmarkIcon from 'assets/icons/bookmark.svg';
import HeartBlackIcon from 'assets/icons/heart-black.svg';
import ShareBlackIcon from 'assets/icons/share-black.svg';
import ShopingCartIcon from 'assets/icons/shoping-cart.svg';
import DisplayBlackIcon from 'assets/icons/display-black.svg';
import BookmarkBlackIcon from 'assets/icons/bookmark-black.svg';
import { CustomCheckbox } from '../../UI/checkbox';

export const ImageItem = ({ item, className }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <li
      className={classnames(
        'relative group h-full w-full rounded-xl transform transition-transform duration-200 ease-in-out list-none',
        className
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* IMAGE */}
      <div className="h-full w-full">
        <img
          src={item.imageUrl}
          alt={item.title}
          className="w-full h-full object-cover rounded-xl"
          loading="lazy"
        />
      </div>

      {/* GRADIENT */}
      <div className="absolute top-0 left-0 opacity-100 h-full w-full flex flex-col justify-center items-stretch rounded-xl sm:visible sm:opacity-100">
        <div className="h-[70px] w-full mb-auto rounded-t-xl bg-gradient-to-b from-[#272727]/90 from-10"></div>
        <div className="h-[70px] w-full rounded-b-xl bg-gradient-to-t from-[#272727]/90 from-10%"></div>
      </div>

      {/* OVERLAY: TOP */}
      <div className="absolute left-[10px] items-start top-[10px] opacity-100 w-[calc(100%-20px)] flex justify-between  gap-2  sm:visible sm:opacity-100">
        <div className="flex flex-row flex-wrap items-start ">
          <CustomCheckbox className='mr-[7px]'/>
          <h5 className="text-sm text-white">{item.title}</h5>
          {/* <p className="text-xs text-[#FCFCFD]">{item.description}</p> */}
        </div>
        <OverlayButton
          buttonUrl={ShopingCartIcon}
          hoverIconUrl={ShopingCartIcon}
          overlayPlacement="top"
        />
      </div>

      {/* OVERLAY: BOTTOM */}
      <div className="absolute left-[10px] bottom-[10px] opacity-100 w-[calc(100%-20px)] flex justify-between items-center gap-2 sm:visible sm:opacity-100">
        <OverlayButton
        className='inline-flex'
          buttonUrl={DisplayIcon}
          hoverIconUrl={DisplayBlackIcon}
          overlayPlacement="down"
          text='Похожие'
        />

        <div className="flex items-center gap-2">
          <OverlayButton
            buttonUrl={BookmarkIcon}
            hoverIconUrl={BookmarkBlackIcon}
            overlayPlacement="down"
          />
          <OverlayButton
            buttonUrl={HeartIcon}
            hoverIconUrl={HeartBlackIcon}
            overlayPlacement="down"
          />
          <OverlayButton
            buttonUrl={ShareIcon}
            color='white'
            hoverIconUrl={ShareBlackIcon}
            overlayPlacement="down"
          />
        </div>
      </div>
    </li>
  );
};
