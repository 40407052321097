import { useState, useEffect, useRef, memo } from 'react';
import WaveSurfer from 'wavesurfer.js';

import PlayIcon from 'assets/icons/play.svg';
import PauseIcon from 'assets/icons/pause.svg';
import HeartBlackIcon from 'assets/icons/heart-black.svg';
import ShareBlackIcon from 'assets/icons/share-black.svg';
import SpeakerLoudIcon from 'assets/icons/speaker-loud.svg';
import SpeakerOffIcon from 'assets/icons/speaker-off.svg';
import UploadBlackIcon from 'assets/icons/upload-black.svg';
import BookmarkBlackIcon from 'assets/icons/bookmark-black.svg';
import ShopingCartBlueIcon from 'assets/icons/cart-blue.svg';
import OverlayButton from './components/OverlayButton';
import TrackInfo from './components/TrackInfo';

const formatDuration = (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

export const ContentItem = memo(
  ({ item, isPlaying, onPlay, onPause, volume = 100, onItemClick, data }) => {
    const waveformRef = useRef(null);
    const waveSurferRef = useRef(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [duration, setDuration] = useState(0);
    const [isMuted, setIsMuted] = useState(false);
    const currentPath = window.location.pathname;

    useEffect(() => {
      if (!item?.imageid) return;
      const generatedAudioUrl = `https://thumbs.dreamstime.com/audiothumb_${item.imageid.slice(
        0,
        5
      )}/${item.imageid}.mp3`;
      setAudioUrl(generatedAudioUrl);
    }, [item?.imageid]);

    useEffect(() => {
      if (!audioUrl || waveSurferRef.current) return;

      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#E1E9FF',
        progressColor: '#3F63DD',
        height: 72,
        barWidth: 0,
        responsive: true,
      });

      waveSurferRef.current.load(audioUrl);

      waveSurferRef.current.on('ready', () => {
        setLoading(false);
        setDuration(waveSurferRef.current.getDuration());
      });

      waveSurferRef.current.on('finish', onPause);

      return () => {
        waveSurferRef.current?.destroy();
        waveSurferRef.current = null;
      };
    }, [audioUrl, onPause]);

    useEffect(() => {
      if (waveSurferRef.current) {
        waveSurferRef.current.setVolume(isMuted ? 0 : volume / 100);
      }
    }, [isMuted, volume]);

    useEffect(() => {
      if (waveSurferRef.current) {
        if (isPlaying) {
          waveSurferRef.current.play();
        } else {
          waveSurferRef.current.pause();
        }
      }
    }, [isPlaying]);

    const toggleMute = () => {
      setIsMuted((prev) => !prev);
    };

    if (!audioUrl) return null;

    return (
      <li className="w-full h-[112px] justify-between flex items-center px-8 rounded-xl bg-white sm:flex-col sm:p-5 sm:h-full sm:max-w-[360px] sm:items-start relative">
        <div
          className={`flex ${currentPath.includes('search-results') && 'w-full'}items-center`}
        >
          {/* Блок с кнопкой управления аудио */}
          <div className="w-16 h-16 rounded-full flex justify-center items-center bg-[#1F2D5C] sm:w-12 sm:h-12">
            <button onClick={isPlaying ? onPause : onPlay} disabled={loading}>
              <img
                src={isPlaying ? PauseIcon : PlayIcon}
                alt="audio-control"
                className="cursor-pointer w-6 h-6"
              />
            </button>
          </div>

          {/* TrackInfo - занимает оставшееся пространство */}
          {(currentPath.includes('search-results') ||
            (data && data?.length >= 2)) && (
            <TrackInfo
              title={item?.title || 'Название музыкального трека'}
              genre={item?.genre || 'Музыка'}
              author={item?.author || 'Автор неизвестен'}
              onItemClick={onItemClick}
              item={item}
              className="flex-grow ml-4"
            />
          )}
        </div>

        {loading && <div className="text-center">Загрузка...</div>}

        <div className="">
          {/* Визуализация формы волны */}
          <div ref={waveformRef} className="waveform  mx-2 w-[392px]"></div>
        </div>
        <div className="flex items-center sm:justify-between sm:w-full sm:ml-0 sm:mt-1.5">
          {/* Продолжительность */}
          <div className="ml-4 text-sm text-[#1C2024] sm:ml-0">
            {formatDuration(duration)}
          </div>

          {/* Иконка громкости */}
          <img
            src={isMuted ? SpeakerOffIcon : SpeakerLoudIcon}
            className="ml-3 cursor-pointer"
            alt="speaker-loud-icon"
            onClick={toggleMute}
          />
        </div>

        {(currentPath.includes('search-results') ||
          (data && data?.length >= 2)) && (
          <>
            <div className="flex justify-between items-center gap-3 sm:mt-4 sm:items-start">
              <OverlayButton buttonUrl={UploadBlackIcon} />
              <OverlayButton buttonUrl={BookmarkBlackIcon} />
              <OverlayButton buttonUrl={HeartBlackIcon} />
              <OverlayButton buttonUrl={ShareBlackIcon} />
              <OverlayButton
                buttonUrl={ShopingCartBlueIcon}
                className="sm:hidden"
              />
            </div>
          </>
        )}
      </li>
    );
  }
);
