export const tabs = [
  { id: 0, name: 'Тренды' },
  { id: 1, name: 'Технологии' },
  { id: 2, name: 'Бизнес' },
  { id: 3, name: 'Наука' },
  { id: 4, name: 'Искусство' },
];

export const cardsData = [
  {
    id: 1,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Основы React: Как начать работать',
    author: 'Юрий Смирнов',
    date: '28 января 2025',
    cardTags: {
      readingTime: '5 минут',
      views: '1200 просмотров',
      actual: true, // актуальное поле
    },
  },
  {
    id: 2,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Как управлять состоянием с Redux Toolkit',
    author: 'Анна Иванова',
    date: '26 января 2025',
    cardTags: {
      readingTime: '5 минут',
      views: '1200 просмотров',
      actual: true, // актуальное поле
    },
  },
  {
    id: 3,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Оптимизация Next.js для SEO',
    author: 'Олег Петров',
    date: '20 января 2025',
    cardTags: {
      readingTime: '8 минут',
      views: '1540 просмотров',
      actual: false, // актуальное поле
    },
  },
  {
    id: 4,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'TypeScript: Советы и лучшие практики',
    author: 'Виктор Соловьёв',
    date: '15 января 2025',
    cardTags: {
      readingTime: '10 минут',
      views: '830 просмотров',
      actual: true, // актуальное поле
    },
  },
  {
    id: 5,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Создание REST API с Node.js и Express',
    author: 'Екатерина Новикова',
    date: '10 января 2025',
    cardTags: {
      readingTime: '12 минут',
      views: '2000 просмотров',
      actual: true, // актуальное поле
    },
  },
  {
    id: 6,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Эффективная работа с Tailwind CSS',
    author: 'Дмитрий Козлов',
    date: '5 января 2025',
    cardTags: {
      readingTime: '6 минут',
      views: '1750 просмотров',
      actual: false, // актуальное поле
    },
  },
  {
    id: 7,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Новшества JavaScript: ES6 и более',
    author: 'Михаил Яковлев',
    date: '1 января 2025',
    cardTags: {
      readingTime: '7 минут',
      views: '1100 просмотров',
      actual: false,
    },
  },
  {
    id: 8,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Python для Data Science: Основы и инструменты',
    author: 'Светлана Бондаренко',
    date: '28 декабря 2024',
    cardTags: {
      readingTime: '10 минут',
      views: '1500 просмотров',
      actual: true,
    },
  },
  {
    id: 9,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Введение в GraphQL: Современный подход к API',
    author: 'Дмитрий Иванов',
    date: '20 декабря 2024',
    cardTags: {
      readingTime: '9 минут',
      views: '1800 просмотров',
      actual: true,
    },
  },
  {
    id: 10,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Программирование на Vue.js: Простые решения для сложных задач',
    author: 'Марина Кузнецова',
    date: '15 декабря 2024',
    cardTags: {
      readingTime: '6 минут',
      views: '800 просмотров',
      actual: false,
    },
  },
  {
    id: 11,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Как настроить Docker для эффективной работы',
    author: 'Юрий Смирнов',
    date: '10 декабря 2024',
    cardTags: {
      readingTime: '12 минут',
      views: '1000 просмотров',
      actual: true,
    },
  },
  {
    id: 12,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title:
      'Основы машинного обучения: Погружение в AI  Погружение в AI  Погружение в AI',
    author: 'Иван Петров',
    date: '1 декабря 2024',
    cardTags: {
      readingTime: '15 минут',
      views: '1300 просмотров',
      actual: false,
    },
  },
  {
    id: 13,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Изучаем Go: Основы и примеры',
    author: 'Алексей Волков',
    date: '28 ноября 2024',
    cardTags: {
      readingTime: '8 минут',
      views: '1100 просмотров',
      actual: false,
    },
  },
  {
    id: 14,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Программирование на C++: Руководство для начинающих',
    author: 'Наталья Сергеева',
    date: '20 ноября 2024',
    cardTags: {
      readingTime: '20 минут',
      views: '2500 просмотров',
      actual: false,
    },
  },
  {
    id: 15,
    image: 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
    title: 'Оптимизация запросов в SQL: Лучшие практики',
    author: 'Игорь Волков',
    date: '15 ноября 2024',
    cardTags: {
      readingTime: '10 минут',
      views: '1700 просмотров',
      actual: true,
    },
  },
];
