import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchSearchResultsQuery } from 'rtk/SearchApi';
import { ContentItem } from './audio-item';
import { setItemsContent, setIsLoading } from 'rtk/Slices/dataSlice';

export const AudioContentList = ({
  className = '',
  typeContent = 'audio',
  idKey = 'imageid',
  id = null,
  renderContentItem,
}) => {
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [volume, setVolume] = useState(100);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { searchText, filters, page, itemsPerPage, sortBy } = useSelector(
    (state) => state.data
  );

  const { data, error, isFetching } = useFetchSearchResultsQuery({
    searchText,
    page,
    itemsPerPage,
    typeContent,
    sortBy,
    filters,
  });

  const handleItemClick = useCallback(
    (item) => {
      navigate(`/product/${typeContent}/${item[idKey]}`);
    },
    [navigate, typeContent, idKey]
  );

  const handlePlay = useCallback((id) => {
    setCurrentlyPlaying((prev) => (prev === id ? null : id));
  }, []);

  const handlePause = useCallback(() => {
    setCurrentlyPlaying(null);
  }, []);

  const handleVolumeChange = useCallback((newVolume) => {
    setVolume(newVolume);
  }, []);

  useEffect(() => {
    dispatch(setIsLoading(true));
    if (data?.result?.items?.item) {
      dispatch(setItemsContent(data.result.items.item));
    } else {
      dispatch(setItemsContent([]));
    }
    dispatch(setIsLoading(false));
  }, [data, dispatch]);

  if (isFetching) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Ошибка загрузки данных: {error.message}</div>;
  }

  if (id) {
    const item = data?.result?.items?.item?.find((item) => item[idKey] === id);
    if (!item) {
      return <div>Контент не найден</div>;
    }

    return (
      <div className={`flex flex-col gap-1.5 ${className}`}>
        {renderContentItem ? (
          renderContentItem(item)
        ) : (
          <ContentItem
            key={item[idKey]}
            item={item}
            isPlaying={currentlyPlaying === item[idKey]}
            onPlay={() => handlePlay(item[idKey])}
            onPause={handlePause}
            volume={volume}
            setVolume={handleVolumeChange}
            onItemClick={() => handleItemClick(item)}
          />
        )}
      </div>
    );
  }

  const items = data?.result?.items?.item || [];

  return (
    <ul
      className={`flex-1 flex flex-col gap-1.5 sm:items-center sm:gap-4 ${className}`}
    >
      {items.map((item) => {
        return (
          <ContentItem
            id={id}
            key={item[idKey]}
            item={item}
            data={items}
            isPlaying={currentlyPlaying === item[idKey]}
            onPlay={() => handlePlay(item[idKey])}
            onPause={handlePause}
            volume={volume}
            setVolume={handleVolumeChange}
            onItemClick={() => handleItemClick(item)}
          />
        );
      })}
    </ul>
  );
};
