import { Button } from '../button';
import { Sorting } from './sorting';
import { FiltersList } from './filters-list';
import { SidebarDialog } from '../sidebar-dialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSortBy,
  selectSortBy,
  setFilters,
  selectFilters,
} from '../../rtk/Slices/dataSlice';

export const Filters = ({ setSidebarOpen }) => {
  const dispatch = useDispatch();
  const { searchText, totalResults, typeContent } = useSelector(
    (state) => state.data
  );
  const sortBy = useSelector(selectSortBy);
  const filters = useSelector(selectFilters);
  const { searchType } = useSelector((state) => state.similarImages);

  const onSortByChange = (newSortBy) => {
    dispatch(setSortBy(newSortBy));
  };
  const sortedByOptions = [
    { value: 'bestselling', label: 'рейтингу' },
    { value: 'uploaddate', label: 'новизне' },
    { value: 'downloads', label: 'качеству' },
  ];
  const handleDeleteFilter = (filterKey) => {
    const updatedFilters = { ...filters };

    if (typeof updatedFilters[filterKey] === 'object') {
      updatedFilters[filterKey] = {
        subscription: undefined,
        single_purchase: undefined,
      };
    } else {
      updatedFilters[filterKey] = '';
    }

    dispatch(setFilters(updatedFilters));
  };

  const categories = [
    { type: 'video', label: 'Видео' },
    { type: 'image', label: 'Изображения' },
    { type: 'document', label: 'Документы' },
    { type: 'audio', label: 'Аудио' },
  ];

  const selectedCategoryLabel =
    categories.find((category) => category.type === typeContent)?.label ||
    'Видео';

  const filterItems = Object.entries(filters)
    .map(([key, value]) => (value ? `${key}: ${value}` : null))
    .filter(Boolean);

  return (
    <div className="mt-[5px] flex flex-col">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Button
            title="Фильтры"
            className="group h-[48px] px-6 bg-[#1F2D5C] rounded-lg text-white mr-[23px] sm:hidden"
            onClick={() => setSidebarOpen((open) => !open)}
          >
            <div className="ml-1 w-[21px] h-[21px] flex items-center justify-center bg-white rounded-full text-black text-[12px] leading-4 transition-all duration-300 font-medium group-hover:bg-[#1F2D5C] group-hover:text-white">
              {filterItems.length}
            </div>
          </Button>

          <div className="flex flex-col gap-1">
            <h3 className="font-bold text-2xl">
              {searchType === 'url' ? (
                <>Поиск по похожим</>
              ) : (
                <>
                  {' '}
                  {selectedCategoryLabel} по запросу «{searchText}»
                </>
              )}
            </h3>

            <p className="text-sm text-[#60646C]">{totalResults} материала</p>
          </div>
        </div>
        <Sorting
          sortedByOptions={sortedByOptions}
          setSortBy={onSortByChange}
          sortBy={sortBy}
        />
      </div>

      <div className="mt-5 flex justify-between">
        <FiltersList
          filterItems={filterItems}
          onDeleteFilterItem={(filter) => {
            const [key] = filter.split(':');
            handleDeleteFilter(key.trim());
          }}
        />
        <SidebarDialog />
      </div>
    </div>
  );
};
