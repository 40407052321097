import { useDispatch, useSelector } from 'react-redux';
import { setSortBy, setItemsPerPage } from '../../rtk/Slices/dataSlice';
import { SortedSelect } from './sorted-select';
import Select from '../../UI/select/Select';
import cx from 'classnames';

export const Sorting = ({ sortedByOptions }) => {
  const dispatch = useDispatch();
  const { itemsPerPage, sortBy } = useSelector((state) => state.data);

  const handleSortByChange = (value) => {
    dispatch(setSortBy(value));
  };

  const handleItemsPerPageChange = (value) => {
    dispatch(setItemsPerPage(value));
  };

  const itemsPerPageOptions = [48, 64, 96, 120];

  return (
    <div className="flex gap-5 sm:hidden">
      <div className="flex items-center font-medium text-sm">
        <p>Сортировать по:</p>
        <SortedSelect
          sortedByOptions={sortedByOptions}
          value={sortBy}
          onChange={handleSortByChange}
        />
      </div>

      <div className="flex items-center font-medium text-sm">
        <p>Количество на странице:</p>
        <Select
          options={itemsPerPageOptions}
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        />
      </div>
    </div>
  );
};

export const SortingByOptions = ({sortedByOptions, className}) => {
  return (
    <div className={cx(`flex items-center font-medium text-sm ${className}`)}>
      <p>Сортировать по:</p>
      <SortedSelect
        sortedByOptions={sortedByOptions}
        // value={sortBy}
        // onChange={handleSortByChange}
      />
    </div>
  );
};
