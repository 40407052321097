import { Link } from 'react-router-dom';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import classnames from 'classnames';

export const SidebarItem = ({
  url,
  label,
  route,
  onClick,
  className,
  profileNavigation,
  isMobile,
  closeSidebar,
  isLastItem, 
}) => {
  const routePath = `/profile/${route}`;

  const handleClick = (e) => {
    if (onClick) onClick(e);
    if (isMobile && closeSidebar) closeSidebar(); 
  };

  const isActive = profileNavigation === route; 

  return (
    <Link to={routePath} onClick={handleClick} className="w-full">
      <div
        className={classnames(
          'group h-10 flex justify-between items-center pl-[10px] pr-3 rounded-md cursor-pointer md:h-[72px] md:pl-5 md:pr-[10px]',
          className,
          {
            'bg-[#EDF2FE] md:bg-transparent': isActive,
            '!text-[#80838D]': label === 'Выйти',
            'md:border-b md:border-solid md:border-[#F0F0F3]': isMobile && !isLastItem,
            'md:last:border-none': isMobile && isLastItem, 
          }
        )}
      >
        <div className="flex gap-2">
          <img src={url} alt="icon" />
          <div
            className={classnames('text-[#1C2024] text-sm leading-5 md:text-base md:leading-6', {
              'font-medium text-[#1F2D5C] md:font-normal md:text-[#1C2024]': isActive,
              '!text-[#80838D]': label === 'Выйти',
            })}
          >
            {label}
          </div>
        </div>

        {(isMobile || isActive) && (
          <img src={ChevronRightIcon} alt="chevron-right" className="size-4 md:size-6" />
        )}
      </div>
    </Link>
  );
};
