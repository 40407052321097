export const prepareFiltersForRequest = (filters) => {
  const params = {};

  Object.entries(filters).forEach(([key, value]) => {
    if (value !== undefined) {
      if (key === 'modelAge') {
        // Проверяем, если value это объект, то берем только нужное свойство
        if (typeof value === 'object' && !Array.isArray(value)) {
          // Если это объект, то нужно правильно извлечь его ключи или значения
          params[key] = Object.keys(value).join(','); // Можно извлечь ключи или значения, в зависимости от нужд
        } else {
          // В любом другом случае, оставляем как есть
          params[key] = value;
        }
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        // Для других объектов, например для orientation
        params[key] = Object.keys(value)
          .filter((subKey) => value[subKey])
          .join(',');
      } else {
        params[key] = value;
      }
    }
  });

  return params;
};
