import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Tags } from './tags';
import { Content } from './content';
import { VideoPlayer } from '../../components';
import { ProductDescription } from './product-description';
import { ProductSelling } from './product-selling';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg';
import ChevronLeftIcon from '../../assets/icons/chevron-left.svg';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { AudioContentList } from '../../components';

export const Product = () => {
  const { type, id } = useParams();
  const [openDescription, setOpenDescription] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);

  const renderContent = () => {
    if (!audioData) {
      return <div>Данные не найдены или загружаются...</div>;
    }

    const getBestAvailableImage = () => {
      return (
        audioData.extralargeThumb ||
        audioData.largeThumb ||
        audioData.mediumThumb ||
        audioData.smallThumb ||
        null
      );
    };

    const getBestAvailableVideo = () => {
      return audioData.videoThumb || audioData.smallvideoThumb || null;
    };

    switch (type) {
      case 'audio':
        return <AudioContentList id={id} className="your-class" />;

      case 'image': {
        const bestImage = getBestAvailableImage();
        return bestImage ? (
          <div className="max-h-[495px] bg-gray-300 flex justify-center items-center">
            <img src={bestImage} className="h-full" alt={audioData.title} />
          </div>
        ) : (
          <div>Изображение недоступно</div>
        );
      }

      case 'video': {
        const bestVideo = getBestAvailableVideo();
        return bestVideo ? (
          <VideoPlayer url={bestVideo} alt={audioData.title} />
        ) : (
          <div>Видео недоступно</div>
        );
      }

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!id || !type) {
      console.error('ID или тип не передан');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/${type}/${id}`
        );
        console.log('Ответ от сервера:', response.data);
        setAudioData(response.data.result); // Загружаем данные
        setTags(response.data.result?.keywords?.split(' ') || []); // Теги
        setLoading(false); // Завершаем загрузку
      } catch (error) {
        console.error('Ошибка при запросе:', error);
        setLoading(false); // Завершаем загрузку
      }
    };

    fetchData(); // Загружаем данные
  }, [id, type]); // Зависимости для перезапуска эффекта

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (!audioData) {
    return <div>Данные не найдены для ID: {id}</div>; // Выводим сообщение, если данных нет
  }

  return (
    <div className="container mx-auto px-10 flex flex-col gap-[9px] mt-[42px] sm:px-5">
      <div className="flex items-center cursor-pointer">
        <img src={ChevronLeftIcon} alt="chevron-left" className="size-5" />
        <div className="ml-1.5 font-semibold text-[#1F2D5C]">
          <Link to="/search-results">Вернуться к поиску</Link>
        </div>
      </div>
      <div className="mt-6 flex gap-12 sm:mt-0">
        <div className="flex-1 flex flex-col sm:hidden">
          {renderContent()} {/* Рендерим контент в зависимости от типа */}
          <div className="mt-12 flex justify-between flex-col">
            <div
              className="h-[32px] flex items-center cursor-pointer"
              onClick={() => setOpenDescription((open) => !open)}
            >
              <div className="mr-3 font-medium text-sm text-[#1C2024]">
                Описание и данные
              </div>

              <img
                src={ChevronDownIcon}
                alt="chevron-down"
                className={classnames('size-4', {
                  'rotate-180': openDescription,
                  'rotate-0': !openDescription,
                })}
              />
            </div>
            {!openDescription && <Tags tags={tags} />}
          </div>
        </div>

        <ProductSelling audioData={audioData} type={type} />
      </div>
      <hr className="w-full mt-6 border-solid -border border-[#F0F0F3] sm:hidden" />
      <Tags tags={tags} className="hidden mt-8 sm:flex" />

      <ProductDescription
        audioData={audioData}
        type={type}
        tags={tags}
        openDescription={openDescription}
        setOpenDescription={setOpenDescription}
        license="Открытая лицензия"
        bitrate="300 Mbps"
        frameRate="60 fps"
        usage="Личное"
        bitDepth="32"
        shootingLocation="Москва"
        modelRelease="Да"
        looping="Да"
        ownershipRelease="Да"
        alphaMask="Нет"
        alphaChannel="Да"
        duration={audioData?.duration}
      />
      <Content contributorname={audioData.username} type={type} />
    </div>
  );
};
