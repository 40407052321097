import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSearchText } from 'rtk/Slices/dataSlice';
import Sprite from '../../UI/sprite/Sprite';
import SearchSelect from './SearchSelect';
import ResultsLink from './ResultsLink';
import queryString from 'query-string';
import { Formik, Form, Field } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';

import './search.css';

function Search({
  select,
  placeholder = 'Введите ваш запрос |',
  className,
  ...styles
}) {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [selected, setSelected] = useState(select || 'images');
  const navigate = useNavigate();
  const { pathname, ...location } = useLocation();
  const resultsPage = pathname === '/search-results';

  const handleSubmit = (values) => {
    const { value } = values;

    // Отправляем запрос в Redux
    dispatch(setSearchText(value));

    // Перенаправляем на страницу результатов
    navigate(`/search-results?${selected}=${value}`);
  };

  useEffect(() => {
    if (resultsPage) {
      const queryParams = queryString.parse(location.search);
      const key = Object.keys(queryParams)[0];
      setSelected(key);
      formikRef.current.setValues({ value: queryParams[key] });
    }
  }, [location.search, resultsPage]);

  return (
    <div className={`${className} search`} style={styles}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ value: '' }}
        innerRef={formikRef}
      >
        {({ handleSubmit }) => (
          <Form className="search__form">
            <SearchSelect selected={selected} setSelected={setSelected} />

            <Field
              className="search__input"
              type="text"
              name="value"
              placeholder={placeholder}
            />

            {resultsPage && <ResultsLink className="search__link-m" />}

            {/* Кнопка поиска */}
            <button
              className="search__button"
              type="submit"
              onClick={() => handleSubmit()}
            >
              <Sprite width={18} height={18} icon="search" />
            </button>
          </Form>
        )}
      </Formik>

      {resultsPage && <ResultsLink />}
    </div>
  );
}

export default Search;
