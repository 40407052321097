import React from 'react';
import SVG from 'react-inlinesvg';

export function Button({
  text,
  color = 'blue',
  className = '', // Классы для кнопки
  textClassName = '', // Классы для текста
  iconClassName = '', // Классы для иконки
  src = null,
  gap = 1,
}) {
  const buttonStyles =
    color === 'blue'
      ? 'bg-[#1F2D5C] text-white  rounded-lg'
      : 'bg-white text-[#1F2D5C] ';
  const textStyles =
    color === 'blue'
      ? 'font-medium text-base text-[#FFFFFF]'
      : 'font-medium text-sm text-[#1C2024]';
  return (
    <button
      className={` rounded-lg flex text-base items-center  ${buttonStyles} ${className}`}
    >
      {text && <span className={`${textClassName} ${textStyles}`}>{text}</span>}
      {src && <SVG className={` ${iconClassName}  `} src={src} />}
    </button>
  );
}
