import { Dnd } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../rtk/Slices/dataSlice';
import * as Accordion from '@radix-ui/react-accordion';
import { SidebarAccordionType } from './components';
import Sprite from '../../UI/sprite/Sprite';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg';
import {
  peopleOptions,
  modelAgeOptions,
  ethnicityOptions,
  colorOptions,
  orientationOptions,
  genderOptions,
  modelsOptions,
  levelOptions,
} from '../filtersData';
import CrossIconSVG from '../../assets/icons/cross.svg';
import SVG from 'react-inlinesvg';
import { useState } from 'react';
import './filter-sidebar.css';

export const FiltersSidebar = () => {
  const { filters } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState(filters.contributorname || '');

  const handleFilterChange = (filterKey, value) => {
    dispatch(
      setFilters({
        ...filters,
        [filterKey]: value || '',
      })
    );
  };

  const resetFilters = (event) => {
    event.preventDefault();
    setSearchTerm('');
    dispatch(
      setFilters({
        models: '',
        contributorname: '',
        subscription_type: '',
        modelAge: '',
        ethnicity: '',
        color: '',
        orientation: '',
        people: '',
        editorChoice: '',
        level: '',
      })
    );
  };

  const handleContributornameChange = (value) => {
    setSearchTerm(value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(
      setFilters({
        ...filters,
        contributorname: searchTerm.trim().toLowerCase(),
      })
    );
  };

  return (
    <div className="w-[332px] p-5 pr-8 flex flex-col items-center rounded-2xl border-solid border-[1px] border-[#E0E1E6] sm:p-0 sm:mt-6 sm:w-full sm:border-none sm:h-full">
      <Dnd />
      <Accordion.Root className="w-full mt-8 sm:mt-4 " type="multiple">
        {[
          { label: 'Цена', key: 'level', options: levelOptions },
          { label: 'Люди', key: 'people', options: peopleOptions },
          { label: 'Количество', key: 'models', options: modelsOptions },
          { label: 'Пол', key: 'gender', options: genderOptions },
          { label: 'Возраст', key: 'modelAge', options: modelAgeOptions },
          { label: 'Этнос', key: 'ethnicity', options: ethnicityOptions },
          { label: 'Цвет', key: 'color', options: colorOptions },
          {
            label: 'Ориентация',
            key: 'orientation',
            options: orientationOptions,
          },
        ].map(({ label, key, options }) => (
          <SidebarAccordionType
            key={key}
            label={label}
            filterKey={key}
            options={options}
            filters={filters}
            handleNestedFilterChange={handleFilterChange}
          />
        ))}
        <Accordion.Item className="mb-4" value="autor">
          <Accordion.Trigger className="w-full h-5 flex items-center justify-between">
            <div className="text-sm font-medium text-[#1C2024]">Авторы</div>
            <img src={ChevronDownIcon} alt="chevron-down-icon" />
          </Accordion.Trigger>
          <Accordion.Content className="mt-8">
            <div className="search">
              <form className="search__form" onSubmit={handleSearchSubmit}>
                <input
                  name="contributorname"
                  className="search__input"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => handleContributornameChange(e.target.value)}
                  placeholder="Поиск по автору"
                />
                <button className="search__button" type="submit">
                  <Sprite width={18} height={18} icon="search" />
                </button>
              </form>
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>

      <button
        onClick={resetFilters}
        className="flex items-center justify-center bg-[#F0F0F3] rounded-b-[12px] py-[19px] w-full"
      >
        <SVG
          src={CrossIconSVG}
          alt="cross-icon"
          className="cursor-pointer mr-3 path-[#B9BBC6]!important ClearFilterIcon"
        />
        <p className="text-[#B9BBC6]">Очистить фильтр</p>
      </button>
    </div>
  );
};
