import classnames from 'classnames';

const OverlayButton = ({ buttonUrl, className }) => (
  <div
    className={classnames(
      'h-12 w-12 flex justify-center items-center rounded-lg border-[1.5px] border-solid border-[#E0E1E6] cursor-pointer last:bg-[#D2DEFF] last:border-none',
      className
    )}
  >
    <img src={buttonUrl} alt="icon" />
  </div>
);

export default OverlayButton;
