import React from 'react';
import Preview from 'assets/icons/Preview.svg';
import ShareBlog from 'assets/icons/shareBlog.svg';
import { SidebarCheckbox } from './checkbox';
import { cards } from './data';
import { SearchInput } from 'components/SearchInput';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { SortingByOptions } from 'components/filters/sorting';
import Question from 'assets/icons/Question.svg';
import SVG from 'react-inlinesvg';
import { useWindowSize } from 'hooks/useWindowSize';
import SettingIcon from 'assets/icons/settings.svg';

export function History() {
  const { width } = useWindowSize();
  if (width >= 1345)
    return (
      <div className="max-w-full">
        <div className="flex justify-between  mb-[31px]">
          <div className="flex gap-3">
            <button className="bg-[#1F2D5C] rounded-lg py-3 px-[29px] text-[#FFFFFF] font-medium">
              Фильтры
            </button>
            <button className="bg-[#FFFFFF] rounded-lg py-[14px] px-4 text-[#1C2024] text-sm font-medium flex items-center">
              Видео: 8274 <ChevronDownIcon className="ml-6 " />
            </button>
            <button className="bg-[#FFFFFF] rounded-lg py-[14px] px-4 text-[#1C2024] text-sm font-medium flex items-center">
              Действия <ChevronDownIcon className="ml-6 " />
            </button>
            <SearchInput
              placeholder="Поиск по ID или ключевым словам"
              classNameInput="w-[403px]"
              className="max-w-full"
            />
          </div>
          <SortingByOptions
            sortedByOptions={['ID', 'Дата создания', 'Изменения']}
          />
          {/* Заголовки таблицы */}
        </div>
        <div className="flex ml-[19px] text-[#202020] font-medium  mb-[14px] text-[16px]">
          <div className="mr-[180px]   flex items-center">
            <SidebarCheckbox
              checkedClassName="!size-4"
              checkboxClassName="!size-4"
              className="mr-[10px]"
            />
            Материал
          </div>
          <div className="mr-[263px] text-[16px]">Описание</div>
          <div className="mr-[252px]  text-[16px]">Лицензия</div>
          <div className="mr-[108px]  text-[16px]">Стоимость</div>
          <div className="mr-[100px]  text-[16px]">Загрузка</div>
        </div>

        {cards.map((card) => (
          <div key={card.id} className="flex mb-4 bg-[#FFFFFF] rounded-xl ">
            <div className="w-[60px]  flex items-center justify-center">
              <SidebarCheckbox
                checkedClassName="!size-4"
                checkboxClassName="!size-4"
                className="ml-[8px]"
              />
            </div>
            <div className="w-[222px] relative h-[158px] mr-[32px]">
              <img
                src={card.imageUrl}
                alt="alt"
                className="w-full object-cover  h-[158px]"
              />
               <div className="absolute top-2 left-2 flex gap-2">
                    <img
                      src={Preview}
                      alt="Play"
                      className="w-4 h-4 cursor-pointer"
                    />
                    <img
                      src={ShareBlog}
                      alt="Share"
                      className="w-4 h-4 cursor-pointer"
                    />
                  </div>
                  <div className="absolute top-[30px] left-4 text-[8px] bg-[#FCFCFD] text-xs font-medium px-[5px] rounded-[4px] text-[#1C2024] py-[3px]">
                    Превью
                  </div>
            </div>

            {/* Описание материала */}
            <div className="w-[280px] flex flex-col gap-3 text-[14px] mt-[22px]">
              <div className="flex  flex-col gap-[1px]">
                <p className="text-[#1C2024]">{card.title}</p>
                <p>Видео &#8226; ID {card.videoId}</p>
              </div>
              <div className="flex  flex-col gap-[1px]">
                <p>{card.licenseType}</p>
                <p>
                  {card.resolution} &#8226; {card.format}
                </p>
              </div>
            </div>

            {/* Лицензия */}
            <div className="ml-[57px] mt-[22px] w-[168px] flex flex-col  text-[14px]">
              <p className="text-[#1C2024] flex items-center gap-[14px]">
                Видео (All Media)
                <SVG src={Question} className="mb-[2px]" />
              </p>
              <p className="text-[#1C2024]">{card.licenseDetails}</p>
              <p className="w-[225px] ">
                Исключительность лицензии: {card.exclusivity}
              </p>
            </div>

            {/* Стоимость */}
            <div className=" ml-[157px] mt-[34px] w-[70px]  flex flex-col justify-between text-[18px] font-semibold">
              <p className="text-[#1C2024]">{card.price}</p>
            </div>

            {/* Загрузка */}
            <div className="ml-[121px] flex flex-col gap-[13px] text-sm mt-[27px]">
              <p className="text-[#1F2D5C] underline font-bold">
                {card.materialLink}
              </p>
              <p className="text-[#1F2D5C] underline font-bold">
                {card.licenseLink}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  else {
    return (
      <div className="w-full flex gap-8 flex-col">
        <h1 className="text-[#1C2024] text-[28px]"> История покупок</h1>
        <div className="flex gap-2 w-full">
          <SearchInput placeholder="Запрос... |" className="flex-1" />
          <div className="bg-[#1F2D5C] rounded-lg p-3">
            <SVG src={SettingIcon} className="h-6 w-6" />
          </div>
        </div>
        <div className="w-full flex gap-8 flex-row flex-wrap">
    {cards.map((card) => (
      <div className="flex w-[48%] lg:w-full items-center flex-col gap-4 bg-white p-5 rounded-xl " key={card.id}>
        {/* Блок с картинкой слева */}
        <div className="flex gap-4">
          <div className="w-[40%]">
            <img
              src={card.imageUrl}
              alt="alt"
              className="w-full object-cover h-auto rounded-lg"
            />
          </div>

          {/* Второй блок с информацией */}
          <div className="w-[60%] flex flex-col gap-1">
              <p className='text-[#1C2024] font-medium text-sm'>{card.title}</p>
            <div className='text-[#80838D] text-sm'>
              <p>Видео &#8226; ID {card.videoId}</p>
              <p>{card.licenseType}</p>
              <p>
                {card.resolution} &#8226; {card.format}
              </p>
              <div className="text-2xl font-semibold text-[#1C2024]">
            <p>{card.price}</p>
          </div>
            </div>
          </div>
        </div>

        {/* Нижний блок, который будет по центру */}
        <div className="w-full flex flex-col   mt-5 items-start text-sm text-[#1C2024]">
            <p className='mb-3 text-[#1C2024]'>Лицензия</p>
            <div className='mb-6 flex gap-1 flex-col text-sm' >
            <p className='text-[#1C2024] font-medium flex items-center gap-2'>Видео (All Media) <SVG src={Question}/></p>
            <p className='text-[#1C2024] font-medium'>{card.licenseDetails}</p>
            <p className='text-[#80838D]'>Исключительность лицензии: {card.exclusivity}</p>
            </div>

          <div className="text-sm text-[#1F2D5C] font-bold ">
            <p className='underline mb-6'>{card.materialLink}</p>
            <p className='underline'>{card.licenseLink}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
      </div>
    );
  }
}
