import { Layout } from './components';
import { Routes, Route } from 'react-router-dom';
import {
  Author,
  Product,
  Results,
  Homepage,
  ShoppingCart,
  SearchResults,
  Blog,
} from './pages';

import { BlogProduct } from 'pages/Blog/BlogProduct';
import { LegalEntity } from 'pages/profile';
import { History } from 'pages/profile/historyPurchase';

import { ProfileSection } from 'pages/profile/profileSection';
import { SettingsSection } from 'pages/profile/settings-section';
import { SubscriptionSection } from 'pages/profile/subscription-section';
import { PaymentSection } from 'pages/profile/payment-section/payment-section';
import { SupportSection } from 'pages/profile/support-section';
import { CompanySection } from 'pages/profile/company-section';
import { AdminSection } from 'pages/profile/admin-section';

import { Login } from 'pages/login';
import { SubscriptionPlans } from 'pages/subscription/subscription-plans';

import Company from 'components/company/Company';
import LayoutOld from './layout/Layout';
import { Group } from 'pages/profile/admin-section/group';
import { Favorites } from 'pages/profile/favorites/favorites';
import { Lastscreen } from 'pages/profile/ lastscreen/lastscreen';
import { ProtectedRoute } from 'ProtectedRoute';
import {Collections} from 'pages/profile/collections';
import {Preview} from 'pages/profile/preview';

const ROLES = {
  LEGAL_ENTITY: 'LegalEntity',
  INDIVIDUAL: 'Individual',
  SUBACCOUNT: 'Subaccount',
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/" element={<Layout />}>
        <Route path="author" element={<Author />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id" element={<BlogProduct />} />
        <Route path="company" element={<Company />} />
        <Route path="login" element={<Login />} />
        <Route path="subscription" element={<SubscriptionPlans />} />
        <Route path="product/:type/:id" element={<Product />} />
        <Route path="shopping-cart" element={<ShoppingCart />} />
        <Route path="search-results" element={<SearchResults />} />
        <Route
          element={
            <ProtectedRoute
              allowedRoles={[
                ROLES.LEGAL_ENTITY,
                ROLES.INDIVIDUAL,
                ROLES.SUBACCOUNT,
              ]}
            />
          }
        >
          <Route path="profile" element={<LegalEntity />}>
            <Route index path="info" element={<ProfileSection />} />
            <Route path="favorites" element={<Favorites />} />
            <Route path="lastscreen" element={<Lastscreen />} />
            <Route path="settings" element={<SettingsSection />} />
            <Route path="plans" element={<SubscriptionSection />} />
            <Route path="company" element={<CompanySection />} />
            <Route path="payment" element={<PaymentSection />} />
            <Route path="support" element={<SupportSection />} />
            <Route path="admin" element={<AdminSection />} />
            <Route path="historyPurchase" element={<History />} />
            <Route path="group" element={<Group />} />
            <Route path="collections" element={<Collections />} />
            <Route path="preview" element={<Preview />} />
          </Route>
        </Route>
      </Route>
      <Route path="/" element={<LayoutOld />}>
        <Route path="results" element={<Results />} />
      </Route>

      <Route
        path="*"
        element={<p style={{ fontSize: 50 }}>Page not found</p>}
      />
    </Routes>
  );
};
