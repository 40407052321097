import classnames from 'classnames';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import UserImage from '../../assets/images/avatar.png';
import PlayerImage from '../../assets/images/player.png';
import HeartBlackIcon from '../../assets/icons/heart-black.svg';
import ShareBlackIcon from '../../assets/icons/share-black.svg';
import UploadBlackIcon from '../../assets/icons/upload-black.svg';
import BookmarkBlackIcon from '../../assets/icons/bookmark-black.svg';
import { Button } from '../../components';

export const ProductSelling = ({ audioData }) => {
  // Универсальная функция для создания формата
  const createFormat = (
    label,
    width,
    height,
    format,
    price,
    type = 'image',
    filesize = null
  ) => {
    if (!width || !height) return null;
    return {
      label,
      resolution: `${width} x ${height}`,
      format: format,
      price,
      type,
      filesize,
    };
  };

  const videoFormats = [
    createFormat(
      'WEB',
      audioData?.videowebWidth,
      audioData?.videowebHeight,
      audioData?.ext || 'mov',
      3330,
      'video'
    ),
    createFormat(
      '720',
      audioData?.video720Width,
      audioData?.video720Height,
      audioData?.ext || 'mov',
      3330,
      'video'
    ),
    createFormat(
      '1080',
      audioData?.video1080Width,
      audioData?.video1080Height,
      audioData?.ext || 'mov',
      3330,
      'video'
    ),
    createFormat(
      '4K',
      audioData?.video4kWidth,
      audioData?.video4kHeight,
      audioData?.ext || 'mov',
      3330,
      'video'
    ),
  ].filter(Boolean);

  const imageFormats = [
    createFormat(
      'XS',
      audioData?.extrasmallWidth,
      audioData?.extrasmallHeight,
      audioData?.ext || 'jpg',
      3330,
      'image',
      audioData?.extrasmallFilesize
    ),
    createFormat(
      'S',
      audioData?.smallWidth,
      audioData?.smallHeight,
      audioData?.ext || 'jpg',
      3330,
      'image',
      audioData?.smallFilesize
    ),
    createFormat(
      'M',
      audioData?.mediumWidth,
      audioData?.mediumHeight,
      audioData?.ext || 'jpg',
      3330,
      'image',
      audioData?.mediumFilesize
    ),
    createFormat(
      'L',
      audioData?.largeWidth,
      audioData?.largeHeight,
      audioData?.ext || 'jpg',
      3330,
      'image',
      audioData?.largeFilesize
    ),
    createFormat(
      'XL',
      audioData?.extralargeWidth,
      audioData?.extralargeHeight,
      audioData?.ext || 'jpg',
      3330,
      'image',
      audioData?.extralargeFilesize
    ),
    createFormat(
      'MAX',
      audioData?.maximumWidth,
      audioData?.maximumHeight,
      audioData?.ext || 'jpg',
      3330,
      'image',
      audioData?.maximumFilesize
    ),
    createFormat(
      'TIFF',
      audioData?.tiffWidth,
      audioData?.tiffHeight,
      audioData?.ext || 'TIFF',
      3330,
      'image',
      audioData?.tiffFilesize
    ),
  ].filter(Boolean);

  return (
    <div className="w-[428px] flex flex-col mt-[10px] sm:mt-4 sm:order-1 sm:w-full">
      <h1 className="font-medium text-2xl text-[#1C2024] sm:text-xl">
        {audioData.title}
      </h1>

      <div className="mt-2 flex items-center text-[#80838D] text-sm">
        <img src={UserImage} alt="user" />
        <div className="flex items-center ml-1">
          <div>Автор:</div>
          <div className="ml-[2px] underline text-[#3E63DD]">
            {audioData.username}
          </div>
        </div>
        <span className="mx-1">&bull;</span>
        <div>ID {audioData.userid}</div>
      </div>

      <img
        src={PlayerImage}
        alt="player"
        className="hidden w-full mt-4 sm:block"
      />

      {/* Список форматов */}
      <ul className="h-[172px] mt-10 pr-3 flex flex-col gap-4 overflow-auto sm:mt-6">
        {[...videoFormats, ...imageFormats].map((format, index) => (
          <ProductItem key={index} {...format} />
        ))}
      </ul>

      <hr className="w-full mt-4 border-solid -border border-[#F0F0F3] sm:hidden" />

      {/* Контролы */}
      <div className="mt-4 flex justify-between items-center gap-3 sm:mt-6">
        <ProductControls buttonUrl={UploadBlackIcon} title="Скачать превью" />
        <ProductControls buttonUrl={BookmarkBlackIcon} />
        <ProductControls buttonUrl={HeartBlackIcon} />
        <ProductControls buttonUrl={ShareBlackIcon} />
      </div>

      <Button
        title="В корзину"
        className="h-[64px] w-full mt-4 px-6 bg-[#1F2D5C] rounded-lg font-medium text-lg text-white"
      />
    </div>
  );
};

const ProductItem = ({ label, resolution, format, price, type }) => (
  <li className="h-[28px] flex items-center justify-between">
    <form>
      <div className="flex items-center">
        <Checkbox.Root
          className="flex size-5 appearance-none items-center justify-center rounded border border-solid border-[#000932]/10 bg-white outline-none data-[state=checked]:bg-[#1F2D5C]"
          id={label}
        >
          <Checkbox.Indicator className="text-violet11 checked:bg-[#1F2D5C]">
            <CheckIcon className="w-6 h-6 text-white" />
          </Checkbox.Indicator>
        </Checkbox.Root>

        <label
          className="pl-4 font-medium text-xl leading-none text-[#1C2024] sm:text-base whitespace-nowrap"
          htmlFor={label}
        >
          {label}
        </label>
      </div>
    </form>
    <div className="ml-[14px] flex items-center text-[#80838D] text-sm justify-end w-full mr-12">
      {resolution && <div>{resolution}</div>}
      {resolution && format && <span className="mx-1">&bull;</span>}
      {format && <div>{format}</div>}
    </div>

    {price && (
      <div className="font-medium text-xl text-[#1C2024] sm:text-base">
        {price}₽
      </div>
    )}
  </li>
);

const ProductControls = ({ buttonUrl, title }) => {
  return (
    <div
      className={classnames(
        'h-12 w-12 flex justify-center items-center rounded-lg border-[1.5px] border-solid border-[#E0E1E6] cursor-pointer',
        { 'flex-1': title }
      )}
    >
      {title && (
        <div className="mr-1.5 font-medium text-[#1C2024] sm:mr-1">{title}</div>
      )}

      <img src={buttonUrl} alt="shoping-cart" />
    </div>
  );
};
