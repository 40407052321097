import { OverlayButton } from '../overlay-button';
import HeartIcon from 'assets/icons/heart.svg';
import HeartBlackIcon from 'assets/icons/heart-black.svg';
import ShareIcon from 'assets/icons/share.svg';
import ShareBlackIcon from 'assets/icons/share-black.svg';
import DisplayIcon from 'assets/icons/display.svg';
import DisplayBlackIcon from 'assets/icons/display-black.svg';
import BookmarkIcon from 'assets/icons/bookmark.svg';
import BookmarkBlackIcon from 'assets/icons/bookmark-black.svg';
import ShopingCartIcon from 'assets/icons/shoping-cart.svg';

export const Overlay = ({ item }) => (
  <>
    <div className="absolute left-[10px] top-[10px] invisible opacity-0 transition-opacity duration-300 group-hover:visible group-hover:opacity-100 w-[calc(100%-20px)] flex justify-between items-start gap-2 bg-gradient-to-b from-[#fff]to-[#272727] sm:visible sm:opacity-100 z-50">
      <div className="flex-1">
        <h5 className="text-sm text-white">{item?.title}</h5>
        <p className="text-xs text-[#FCFCFD]">{item?.duration}</p>
      </div>
      <OverlayButton
        buttonUrl={ShopingCartIcon}
        hoverIconUrl={ShopingCartIcon}
        overlayPlacement="top"
        className="h-8 w-8"
      />
    </div>

    <div className="absolute left-[10px] bottom-[10px] invisible opacity-0 transition-opacity duration-300 group-hover:visible group-hover:opacity-100 w-[calc(100%-20px)] flex justify-between items-center gap-2 sm:visible sm:opacity-100 z-50">
      <OverlayButton
        buttonUrl={DisplayIcon}
        hoverIconUrl={DisplayBlackIcon}
        overlayPlacement="down"
      />
      <div className="flex items-center gap-2">
        <OverlayButton
          buttonUrl={BookmarkIcon}
          hoverIconUrl={BookmarkBlackIcon}
          overlayPlacement="down"
        />
        <OverlayButton
          buttonUrl={HeartIcon}
          hoverIconUrl={HeartBlackIcon}
          overlayPlacement="down"
        />
        <OverlayButton
          buttonUrl={ShareIcon}
          color='white'
          hoverIconUrl={ShareBlackIcon}
          overlayPlacement="down"
        />
      </div>
    </div>
  </>
);
