import React from 'react';
import ReactPaginate from 'react-paginate';
import './paginate.css';
import Sprite from '../../UI/sprite/Sprite';

const BottomPaginate = React.memo(
  ({ currentPage, pageCount, onPageChange }) => {
    const MAX_PAGE = 125;

    const handlePageChange = (selected) => {
      let selectedPage = selected.selected + 1;
      if (selectedPage > MAX_PAGE) {
        selectedPage = MAX_PAGE;
      }

      onPageChange({ selected: selectedPage - 1 });
    };

    const isDisabled = currentPage >= MAX_PAGE;

    if (currentPage === 0 || !pageCount) return null;

    return (
      <>
        <ReactPaginate
          previousLabel={
            <Sprite
              icon="arrow-left"
              width={26}
              height={26}
              className={isDisabled ? 'disabled' : ''}
            />
          }
          nextLabel={
            <Sprite
              icon="arrow-right"
              width={26}
              height={26}
              className={isDisabled ? 'disabled' : ''}
            />
          }
          pageClassName={`pagination__button ${
            isDisabled ? 'pagination__disabled' : ''
          }`}
          breakClassName={`pagination__button ${
            isDisabled ? 'pagination__disabled' : ''
          }`}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          onPageChange={handlePageChange}
          breakLabel={'...'}
          containerClassName="pagination group"
          activeClassName="pagination__button--active"
          previousClassName="pagination__prev"
          nextClassName="pagination__next"
          forcePage={Math.min(currentPage - 1, MAX_PAGE - 1)}
          disabledClassName="pagination__disabled"
          disabled={isDisabled}
        />

        {currentPage >= MAX_PAGE && (
          <div className="absolute left-[33.5%] bottom-[-142%] bg-red-600 text-white text-xs px-2 py-1 rounded">
            Используйте другие слова для поиска
          </div>
        )}
      </>
    );
  }
);

export default BottomPaginate;
