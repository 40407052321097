import CrossIcon from '../../assets/icons/cross.svg';
import {
  filterLabels,
  editorChoiceOptions,
  peopleOptions,
  genderOptions,
  modelsOptions,
  levelOptions,
  modelAgeOptions,
  ethnicityOptions,
  colorOptions,
  orientationOptions,
} from '../filtersData';

const getOptionLabel = (options, key) => {
  const option = options.find((opt) => opt.key === key);
  return option ? option.label : key;
};

const getDisplayValue = (key, value) => {
  switch (key) {
    case 'editorChoice':
      return getOptionLabel(editorChoiceOptions, value);
    case 'people':
      return getOptionLabel(peopleOptions, value);
    case 'gender':
      return getOptionLabel(genderOptions, value);
    case 'models':
      return getOptionLabel(modelsOptions, value);
    case 'level':
      return getOptionLabel(levelOptions, value);
    case 'modelAge':
      return getOptionLabel(modelAgeOptions, value);
    case 'ethnicity':
      return getOptionLabel(ethnicityOptions, value);
    case 'color':
      return getOptionLabel(colorOptions, value);
    case 'orientation':
      return getOptionLabel(orientationOptions, value);
    default:
      return value;
  }
};

export const FiltersList = ({ filterItems = [], onDeleteFilterItem }) => {
  return (
    <ul className="flex gap-2 flex-wrap">
      {filterItems
        .filter((filter) => filter !== undefined && filter !== '')
        .map((filter) => {
          const [key, value] = filter.split(':').map((item) => item.trim());
          const label = filterLabels[key];
          const displayValue = getDisplayValue(key, value);

          return (
            <li
              key={filter}
              className="h-[36px] pl-[10px] pr-3 flex items-center gap-1 rounded-[6px] bg-[#F0F0F3]"
            >
              <p className="font-medium leading-5 text-sm text-[#1F2D5C]">
                {label}
                {displayValue && `: ${displayValue}`}
              </p>
              <img
                src={CrossIcon}
                alt="cross-icon"
                onClick={() => onDeleteFilterItem(filter)}
                className="cursor-pointer"
              />
            </li>
          );
        })}
    </ul>
  );
};
