import classnames from 'classnames';
import { FormInput } from './form-input';
import { Button } from 'components';
import { Dropdown } from './dropdown';

import PencilIcon from 'assets/icons/pencil.svg';
import Clipboard from 'assets/icons/clipboard.svg';

export const CompanySection = () => {
  return (
    <div
      className={classnames('w-full flex flex-col', {
      })}
    >
      <Dropdown
        url={Clipboard}
        label="Данные моей компании"
        className="border rounded-xl md:flex"
        iconClassName="rotate-90"
      />
      <h1 className="mt-[15px] font-bold text-[#1C2024] text-[35px] leading-10 -tracking-[0.16px] md:text-[28px] md:leading-9 md:mt-8">
      Данные моей компании
      </h1>

      <form className="max-w-[543px] flex flex-col mt-10 gap-6 md:max-w-full md:mt-8">
        <FormInput label="Компания" url={PencilIcon} />
        <FormInput label="ИНН" url={PencilIcon} />
        <FormInput label="Сайт" url={PencilIcon} />
        <FormInput label="Юр. адрес" url={PencilIcon} />
        <FormInput label="Отрасль" url={PencilIcon} />
        <Button
          title="Сохранить изменения"
          className="w-[266px] h-[48px] px-6 bg-[#1F2D5C] rounded-lg text-[#FFF] font-medium md:w-full"
        />
      </form>
    </div>
  );
};

