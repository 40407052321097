import React, {useState} from 'react';
import { SearchInput } from 'components/SearchInput';
import { SortingByOptions } from 'components/filters/sorting';
import { Button } from '../UI/button';
import Setting from 'assets/icons/settings.svg';
import { ImageItem } from './cards/item';
import { Title } from '../UI/title';
import PlusSVG from 'assets/icons/plus.svg'
import SVG from 'react-inlinesvg'
import {RedactCollection} from './redactCollection'

import { Modal } from "components/modal"; // Убедись, что путь к модалке правильный



export function Collections() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  return (
    <div className="w-full">
      <Title className="hidden md:block mb-8">Коллекции</Title>
      <div className="flex flex-row justify-between -mt-[2px] h-12 lg:h-28  lg:flex-wrap gap-y-3 md:h-12 md:flex-row-reverse">
        <div className="flex md:flex-row-reverse gap-3 max-w-[880px] w-full">
          <Button className="px-[29px] md:hidden" text={'Фильтры'} />

          <Button className="hidden md:block md:p-3 p-[10px]" src={Setting} />

          <SearchInput
            placeholder="Поиск по ID или ключевым словам"
            classNameInput="w-[403px] md:text-xs"
            className="w-full"
          />
        </div>

        <div className="flex min-w-[400px] md:w-full  ">
          <SortingByOptions
            sortedByOptions={['ID', 'Дата создания', 'Изменения']}
            className="mr-[30px] md:hidden"
          />
        </div>
      </div>
      <ImageGallery />
      <button
  onClick={handleOpenModal}
  className="fixed bottom-4 right-4 w-[74px] h-[74px] bg-[#303D69] text-white rounded-full flex items-center justify-center shadow-lg transition-transform hover:scale-110 active:scale-95 z-50"
>
  <SVG src={PlusSVG} className='h-[30px] w-[30px]'/>
</button>


      {/* Модальное окно */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} title="Детали изображения" className='max-w-[393px] w-full'>
        <RedactCollection/>
      </Modal>
    </div>
  );
}

const mockData = [
  {
    id: 1,
    title: 'Mountain Landscape',
    description: 'A beautiful view of the mountains during sunset.',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 2,
    title: 'City Skyline',
    description: 'A bustling city skyline at night with lights shining.',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 3,
    title: 'Beach Sunset',
    description: 'Relaxing sunset at the beach with palm trees.',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 4,
    title: 'Forest Path',
    description: 'A peaceful path in the forest surrounded by trees.',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
];
const generateItems = (data, count = 20) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push({ ...data[i % data.length], id: i + 1 }); // Дублируем элементы
  }
  return result;
};

export function ImageGallery() {
  const items = generateItems(mockData); // Генерируем 20 карточек

  return (
    <ul className="grid grid-cols-4  md:grid-cols-1 lg:grid-cols-3 gap-[6px] mt-10 md:mt-8">
      {items.map((item) => (
        <ImageItem key={item.id} item={item} />
      ))}
    </ul>
  );
}

