import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchText: '',
  typeContent: 'image',
  itemsPerPage: 48,
  page: 1,
  sortBy: '',
  itemsContent: [],
  totalResults: 0,
  filters: {
    contributorname: '',
    subscription_type: '',
    editorChoice: '',
    ethnicity: '',
    color: '',
    orientation: '',
  },
  isLoading: false,
  dataFetchedFromUpload: false, // Добавляем это поле
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setTypeContent: (state, action) => {
      state.typeContent = action.payload;
    },
    setItemsContent: (state, action) => {
      state.itemsContent = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
      state.page = 1;
    },
    setTotalResults: (state, action) => {
      state.totalResults = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDataFetchedFromUpload: (state, action) => {
      state.dataFetchedFromUpload = action.payload; // Редуктор для обновления состояния
    },
  },
});

export const {
  setSearchText,
  setPage,
  setItemsPerPage,
  setSortBy,
  setTypeContent,
  setItemsContent,
  setFilters,
  setTotalResults,
  setIsLoading,
  setDataFetchedFromUpload, // Экспортируем редуктор для dataFetchedFromUpload
} = dataSlice.actions;

export const selectIsLoading = (state) => state.data.isLoading;
export const selectItemsContent = (state) => state.data.itemsContent;
export const selectSearchText = (state) => state.data.searchText;
export const selectPage = (state) => state.data.page;
export const selectItemsPerPage = (state) => state.data.itemsPerPage;
export const selectSortBy = (state) => state.data.sortBy;
export const selectFilters = (state) => state.data.filters;
export const selectTotalResults = (state) => state.data.totalResults;
export const selectTypeContent = (state) => state.data.typeContent;
export const selectDataFetchedFromUpload = (state) =>
  state.data.dataFetchedFromUpload; // Селектор для dataFetchedFromUpload

export default dataSlice.reducer;
