import { AppRoutes } from './AppRoutes';

import 'rc-dropdown/assets/index.css';
import 'rc-slider/assets/index.css';
import './styles/index.css';
// import "@radix-ui/themes/styles.css"; // ❗ Если ломает билд, нужно настроить postcss

import { Theme } from '@radix-ui/themes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser, logout } from 'rtk/Slices/authSlice';
import { useFetchProfileQuery } from 'rtk/authApi';

export const App = () => {
  const dispatch = useDispatch();
  const { data: user, error, isLoading } = useFetchProfileQuery();

  useEffect(() => {
    if (!isLoading) {
      if (user) {
        dispatch(setUser(user)); 
      } else if (error) {
        dispatch(logout()); 
      }
    }
  }, [user, error, dispatch, isLoading]);

  return (
    <div className="App">
      <Theme>
        <AppRoutes />
      </Theme>
    </div>
  );
};
