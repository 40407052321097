import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

export const CustomCheckbox = ({ className }) => {
  return (
    <Checkbox.Root
      className={`flex items-center justify-center w-5 h-5 rounded border border-solid border-[#000932]/10 bg-white outline-none data-[state=checked]:bg-[#1F2D5C] ${className}`}
    >
      <Checkbox.Indicator className="text-violet11">
        <CheckIcon className="w-4 h-4 text-white" />
      </Checkbox.Indicator>
    </Checkbox.Root>
  );
};
