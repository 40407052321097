import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CardTags } from './CardTags';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import 'swiper/css';
import './blog.css';

import PlayBlog from 'assets/icons/PlayBlog.svg'
import ShareBlog from 'assets/icons/shareBlog.svg'

import UserImage from 'assets/images/avatar.png'

export const ActualArticlesSlider = ({ articles }) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const maxDots = 4;

  const handleSlideChange = (swiper) => {
    setSwiperInstance(swiper);
    setActiveSlideIndex(swiper.activeIndex);
  };

  const goToSlide = (index) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index);
    }
  };

  const renderPaginationBullets = () => {
    const totalSlides = articles.length;
    let paginationDots = [];

    if (totalSlides <= maxDots) {
      for (let i = 0; i < totalSlides; i++) {
        paginationDots.push(
          <span
            key={i}
            className={`${
              activeSlideIndex === i
                ? 'w-[24px] h-[8px] bg-[#1F2D5C]'
                : 'w-[8px] h-[8px] bg-[#E1E9FF]'
            } rounded-full mx-1 cursor-pointer transition-all duration-300`}
            onClick={() => goToSlide(i)}
          ></span>
        );
      }
    } else {
      let start = Math.max(0, activeSlideIndex - 1);
      let end = Math.min(totalSlides, activeSlideIndex + 3);

      for (let i = start; i < end; i++) {
        paginationDots.push(
          <span
            key={i}
            className={`${
              activeSlideIndex === i
                ? 'w-[24px] h-[8px] bg-[#1F2D5C]'
                : 'w-[8px] h-[8px] bg-[#E1E9FF]'
            } rounded-full cursor-pointer transition-all duration-300`}
            onClick={() => goToSlide(i)}
          ></span>
        );
      }
    }

    return paginationDots;
  };

  return (
    <Swiper
      allowTouchMove={false}
      effect="fade"
      speed={0}
      slidesPerView={1}
      loop={false}
      onSwiper={setSwiperInstance}
      onSlideChange={handleSlideChange}
      className="mt-0  w-full blogSwiper relative"
    >
      {articles.map((card) => (
        <SwiperSlide key={card.id}>
          <div className="flex gap-[24px] justify-between p-[24px] bg-white mt-[28px] mb-[30px] rounded-2xl h-[374px] ">
            <div className="max-w-[454px] max-h-[324px]">
              <CardTags
                readingTime={card.cardTags?.readingTime || 'Не указано'}
                views={card.cardTags?.views || 'Не указано'}
                actual={card.cardTags?.actual}
                
              />
              <h1 className="text-[24px] font-extrabold mt-2 mb-3 max-w-[421px] line-clamp-2">
                {card.title}
              </h1>
              <div className="mt-2 flex items-center text-[#80838D] text-sm">
        <img src={UserImage} alt="user" />
        <div className="flex items-center ml-1">
          <div>Автор:</div>
          <div className="ml-[2px] underline text-[#3E63DD]">
            {card.author}
          </div>
        </div>
        <span className="mx-1">&bull;</span>
        <div>{card.date}</div>
      </div>
              <p className="mt-[24px] mb-3 mr-[10px] text-gray-600 relative overflow-hidden line-clamp-4">
                Зеленый лес. Сказочный лес «Сосны». Нетронутая ель. Лесной узор.
                Движение камеры в лесу. Коричневая трава на земле. Дрон,
                перемещающийся между деревьями прекрасным...
                <span className="absolute inset-0 bg-gradient-to-b from-transparent to-white h-full w-full"></span>
              </p>
              <Link
                to={`/blog/${card.id}`}
                className="text-[#3E63DD] cursor-pointer mb-6 flex gap-1 items-center"
              >
                Читать полностью
                <ChevronRightIcon className="text-[#3E63DD]" />
              </Link>
            </div>
            <div className="max-w-[482px] rounded-2xl relative">
  <img
    alt={card.title}
    src={card.image}
    className="rounded-2xl object-cover w-full h-full"
  />
  
  {/* Блок с иконками Play и Share */}
  <div className="absolute bottom-4 left-4 flex gap-2">
    <img src={ShareBlog} alt="Share" className="w-8 h-8 cursor-pointer" />
    <img src={PlayBlog} alt="Play" className="w-8 h-8 cursor-pointer" />
  </div>
</div>
          </div>
        </SwiperSlide>
      ))}
      <div className="absolute bottom-[56px] left-6 flex gap-1 z-50">
        {renderPaginationBullets()}
      </div>
    </Swiper>
  );
};
