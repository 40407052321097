// ProfileTooltip.jsx
import * as Tooltip from '@radix-ui/react-tooltip';
import UploadIcon from 'assets/icons/upload-black.svg';
import TrashIcon from 'assets/icons/trash-red.svg';

export const ProfileTooltip = () => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className="size-[128px] flex justify-center items-center rounded-full leading-10 bg-[#FFEE00]/30 font-medium text-[35px] text-[#9E6C00] md:size-[96px]">
            A {/* Заглушка для картинки */}
          </div>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content
            className="absolute w-[146px] top-[100px] -left-1.5 rounded-md bg-[#FFF] border border-solid border-[#000932]/10 text-[8px] leading-[10px] text-[#1C2024]"
            sideOffset={5}
          >
            <div className="p-[10px] flex items-center gap-2 text-xs text-[#1C2024] leading-4 cursor-pointer hover:bg-[#F9F9FB] hover:font-medium">
              <img
                src={UploadIcon}
                alt="upload-icon"
                className="size-[14px]"
              />
              <div>Обновить фото</div>
            </div>

            <div className="p-[10px] flex items-center gap-2 text-xs text-[#1C2024] leading-4 cursor-pointer hover:bg-[#F9F9FB] hover:font-medium">
              <img
                src={TrashIcon}
                alt="upload-icon"
                className="size-[14px]"
              />
              <div>Удалить фото</div>
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
