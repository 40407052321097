import TickIcon from 'assets/icons/tick.svg';
import Crown from 'assets/icons/crown.svg';
import SVG from 'react-inlinesvg';
import PencilIcon from 'assets/icons/pencil.svg';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import { ChevronDownIcon } from '@radix-ui/react-icons';

export const GroupSubAcc = () => {
  const navigate = useNavigate(); // Инициализируем useNavigate

  // Данные групп с пользователями
  const groups = [
    {
      id: 1,
      name: 'Команда дизайнеров',
      avatarLetter: 'D',
      role: 'Дизайнер',
      person: '18 пользователей',
      users: [
        {
          id: 123123124,
          name: 'Мария Мцариашвили',
          email: 'mariamtariash@hires.ru',
          permissions: ['Превью', 'Лицензия', 'Управление счетами'],
          role: 'Администратор',
        },
      ],
    },
    {
      id: 2,
      name: 'Команда разработчиков',
      avatarLetter: 'R',
      role: 'Разработчик',
      person: '36 пользователей',
      users: [
        {
          id: 123123124,
          name: 'Мария Мцариашвили',
          email: 'mariamtariash@hires.ru',
          permissions: ['Превью', 'Лицензия', 'Управление счетами'],
          role: 'Администратор',
        },
      ],
    },
    {
      id: 3,
      name: 'Менеджеры',
      avatarLetter: 'M',
      role: 'Менеджер',
      person: '6 пользователей',
      users: [
        {
          id: 123123124,
          name: 'Мария Мцариашвили',
          email: 'mariamtariash@hires.ru',
          permissions: ['Превью', 'Лицензия', 'Управление счетами'],
          role: 'Администратор',
        },
      ],
    },
  ];
  const handleGroupClick = (groupId) => {
    navigate(`/group/${groupId}`);
  };

  // Функция для навигации на страницу с пользователями
  const handleViewUsers = (groupId) => {
    navigate(`/profile/group/`); // Перенаправляем на страницу с пользователями этой группы
  };

  return (
    <div className="flex flex-col gap-[10px] mt-8">
      {groups.map((group) => (
        <div
          key={group.id}
          className="max-w-full xl:flex-col xl:gap-5 flex bg-[#ffffff] pr-6 pl-5 pt-6 pb-6 rounded-xl justify-between"
        >
          <div className="flex items-start md:flex-col">
            <div className="size-[102px] flex justify-center items-center rounded-full bg-[#FFEE00]/30 font-medium text-[35px] text-[#9E6C00] md:size-[96px]">
              {group.avatarLetter}
            </div>

            <div className="ml-5 flex flex-col md:ml-0 md:mt-4">
              <div className="flex items-center gap-[9px]">
                <h1 className="font-bold text-[#1C2024] text-2xl md:text-lg">
                  {group.name}
                </h1>
                <img src={TickIcon} alt="tick" className="size-6" />
                <div
                  className={classnames(
                    'p-2 border border-solid rounded-lg text-lg',
                    group.role === 'Администратор'
                      ? 'bg-[#FFEE9C] border-[#FBE577] text-[#AB6400]'
                      : 'bg-[#E8E8E8] border-[#E0E0E0] text-[#646464]'
                  )}
                >
                  <SVG src={Crown} alt={group.role} className="w-4 h-4" />
                </div>
              </div>
              <div
                className="mt-[3px] text-lg leading-[26px] text-[#000714]/60 cursor-pointer"
                onClick={() => handleViewUsers(group.id)} // Добавляем обработчик клика
              >
                {group.person}
              </div>

              {/* Кнопка редактирования для ПК */}
              <div className="flex flex-row w-full gap-[29px] mt-[6px] items-center sm:flex-col">
                <div className="md:hidden flex items-center gap-1 text-[#1F2D5C] underline">
                  Переименовать <SVG src={PencilIcon} />
                </div>
                <div className="md:hidden flex items-center gap-1 text-[#E5484D]">
                  Удалить
                </div>
              </div>
            </div>
          </div>

          {/* Кнопка редактирования для мобильных */}
          <div className="flex-col md:flex hidden w-full gap-[29px] mt-[6px] items-start sm:flex-col">
            <div className="flex items-center gap-1 text-[#1F2D5C] underline">
              Переименовать <SVG src={PencilIcon} />
            </div>
            <div className="flex items-center gap-1 text-[#E5484D]">
              Удалить
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
