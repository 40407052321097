import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from 'rtk/Slices/authSlice';
import { useLogoutMutation } from 'rtk/authApi';
import Sprite from '../../UI/sprite/Sprite';
import { Dropdown } from 'UI/dropdown';

function User() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [logoutRequest] = useLogoutMutation();

  const handleToggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleLogout = async () => {
    try {
      await logoutRequest().unwrap();
      dispatch(logout());
      setDropdownOpen(false);
    } catch (error) {
      console.error('Ошибка при выходе:', error);
    }
  };

  const buttons = [
    {
      type: 'link',
      label: 'Профиль',
      to: '/profile',
    },
    {
      type: 'button',
      label: 'Выйти',
      onClick: handleLogout,
    },
  ];

  if (!user) {
    return (
      <div className="header__wrapper sm:order-2 relative">
        <Link className="header__sign" to="/login">
          <span>Войти</span>
          <Sprite icon="avatar" width={20} height={20} />
        </Link>
      </div>
    );
  }

  return (
    <div className="header__wrapper sm:order-2 relative">
      <div className="header__user" onClick={handleToggleDropdown}>
        <div
          className="header__photo"
          style={{ backgroundColor: '#f4008c17', color: '#F4008C' }}
        >
          <span>{user.fullName.charAt(0)}</span>
        </div>
        <span className="header__user-name">{user.fullName.split(' ')[0]}</span>
        <Sprite icon="arrow-bottom" width={16} height={16} />
      </div>

      <Dropdown isOpen={isDropdownOpen} buttons={buttons} />
    </div>
  );
}

export default User;
