import React from 'react';
import TickIcon from 'assets/icons/tick.svg';
import Crown from 'assets/icons/crown.svg';
import SVG from 'react-inlinesvg';
import PencilIcon from 'assets/icons/pencil.svg';
import classnames from 'classnames';

export const Group = () => {
  // Массив пользователей
  const users = [
    {
      id: 123123124,
      name: 'Мария Мцариашвили',
      role: 'Администратор',
      permissions: ['Превью', 'Лицензия', 'Управление счетами'],
      email: 'mariamtariash@hires.ru',
    },
    {
      id: 234234235,
      name: 'Иван Иванов',
      role: 'Разработчик',
      permissions: ['Превью', 'Управление счетами'],
      email: 'ivanov@hires.ru',
    },
    {
      id: 345345346,
      name: 'Алексей Смирнов',
      role: 'Менеджер',
      permissions: ['Превью'],
      email: 'smirnov@hires.ru',
    },
  ];

  return (
    <div className="mt-8">
      {/* Заголовки колонок */}
      <div className="flex justify-between font-bold text-lg text-[#1C2024]">
        <div className="w-1/3">Пользователь (ID, Иконка, Почта)</div>
        <div className="w-1/3">Разрешения</div>
        <div className="w-1/3">Роль</div>
      </div>

      {/* Список пользователей */}
      <div className="flex flex-col mt-4 gap-6">
        {users.map((user) => (
          <div key={user.id} className="flex justify-between bg-[#ffffff] p-6 rounded-xl shadow-sm">
            {/* Пользователь (ID, Иконка, Почта) */}
            <div className="w-1/3 flex items-center gap-3">
              <div className="size-[40px] flex justify-center items-center rounded-full bg-[#FFEE00]/30 font-medium text-[20px] text-[#9E6C00]">
                {user.name.charAt(0)}
              </div>
              <div>
                <div>{user.name}</div>
                <div className="text-sm text-[#646464]">{user.email}</div>
                <div className="text-sm text-[#9E6C00]">ID: {user.id}</div>
              </div>
            </div>

            {/* Разрешения */}
            <div className="w-1/3">
              <div className="text-lg">
                {user.permissions.map((permission, index) => (
                  <div key={index}>{permission}</div>
                ))}
              </div>
            </div>

            {/* Роль */}
            <div className="w-1/3 flex items-center gap-2">
              <div
                className={classnames(
                  'p-2 border border-solid rounded-lg text-lg',
                  user.role === 'Администратор'
                    ? 'bg-[#FFEE9C] border-[#FBE577] text-[#AB6400]'
                    : 'bg-[#E8E8E8] border-[#E0E0E0] text-[#646464]'
                )}
              >
                <SVG src={Crown} alt={user.role} className="w-4 h-4" />
              </div>
              <span>{user.role}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
