// ImageContentList.js

import React, { useState, useEffect, useMemo } from 'react';
import { GradientOverlay } from '../GradientOverlay';
import { Overlay } from '../Overlay';

export const ImageContentList = ({ items, onItemClick }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (items && (Array.isArray(items) || typeof items === 'object')) {
      setData(Array.isArray(items) ? items : [items]);
    } else {
      setData([]);
    }
  }, [items]);

  return (
    <ul className="flex-1 grid grid-cols-4 gap-1.5 sm:grid-cols-1">
      {data.map((item, idx) => (
        <ImageItem key={idx} item={item} onItemClick={onItemClick} />
      ))}
    </ul>
  );
};

const ImageItem = React.memo(({ item, onItemClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Логика выбора лучшего изображения
  const bestImage = useMemo(() => {
    return (
      item?.extralargeThumb ||
      item?.largeThumb ||
      item?.mediumThumb ||
      item?.smallThumb ||
      '' // Пустая строка, если ни одно изображение не найдено
    );
  }, [item]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  // Локальная обработка начала перетаскивания на изображении
  const handleDragStart = (e) => {
    if (bestImage) {
      e.dataTransfer.setData('image_url', bestImage); // Передаем URL изображения
    }
    e.dataTransfer.effectAllowed = 'move';
  };

  return (
    <li
      className="relative group w-full h-full rounded-xl transform transition-transform duration-200 ease-in-out hover:scale-105"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onItemClick(item)}
    >
      <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
        {bestImage ? (
          <img
            src={bestImage}
            alt={item?.title || 'Изображение'}
            className="absolute top-0 left-0 w-full h-full object-cover rounded-xl"
            loading="lazy"
            draggable="true" // Только изображение можно перетаскивать
            onDragStart={handleDragStart} // Обработка события dragStart на изображении
          />
        ) : (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 rounded-xl">
            Нет изображения
          </div>
        )}
      </div>
      {/* GradientOverlay и Overlay не должны блокировать перетаскивание */}
      <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
        <GradientOverlay />
        <Overlay item={item} isHovered={isHovered} />
      </div>
    </li>
  );
});

export default ImageContentList;
