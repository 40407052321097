import { useState, useEffect, useCallback } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

export const SidebarCheckbox = ({
  label,
  checked = false,
  onCheckedChange,
  className = ''
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleCheckedChange = useCallback(() => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    if (onCheckedChange) {
      onCheckedChange(newCheckedState);
    }
  }, [isChecked, onCheckedChange]);

  return (
    <div className={`flex items-center ${className}`}>
      <Checkbox.Root
        className="flex size-5 appearance-none items-center justify-center rounded border border-solid border-[#000932]/10 bg-white outline-none data-[state=checked]:bg-[#1F2D5C]" 
        checked={isChecked}
        onCheckedChange={handleCheckedChange}
        id={label}
      >
        <Checkbox.Indicator className="text-violet11 checked:bg-[#1F2D5C]">
          <CheckIcon className="w-6 h-6 text-white" />
        </Checkbox.Indicator>
      </Checkbox.Root>

      <label
        className={`pl-[10px] text-sm leading-none ${
          isChecked ? 'text-[#1C2024]' : 'text-[#60646C]'
        }`}
        htmlFor={label}
      >
        {label}
      </label>
    </div>
  );
};
