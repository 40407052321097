import React from 'react';
import { InputGroup, Input, InputRightElement } from '@chakra-ui/input';
import classnames from 'classnames';

import SearchIcon from 'assets/icons/search.svg';

export const SearchInput = ({
  value,
  onChange,
  onSearch,
  onKeyDown,
  placeholder = 'Поиск',
  classNameInput,
  className,
}) => (
  <InputGroup
    className={classnames(
      'flex-1 relative h-[48px] rounded-lg bg-white',
      className
    )}
  >
    <Input
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={classnames(
        'border-none focus:ring-0 h-full text-sm sm:text-xs pl-4 pr-12 rounded-lg w-full',
        classNameInput
      )}
      aria-label={placeholder}
    />
    <InputRightElement
      cursor="pointer"
      onClick={onSearch}
      aria-label="Начать поиск"
      className="absolute inset-y-0 right-2 flex justify-center items-center w-10 h-full"
    >
      <img src={SearchIcon} alt="search-icon" className="w-5 h-5" />
    </InputRightElement>
  </InputGroup>
);
