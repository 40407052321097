import cx from 'classnames';
import SVG from 'react-inlinesvg';

export const OverlayButton = ({
  buttonUrl,
  hoverIconUrl,
  className,
  overlayPlacement,
  color,
  text,
}) => {
  return (
    <div
      className={cx(
        'inline-flex justify-center items-center h-8 px-2 rounded-md cursor-pointer group/button transition-all duration-300 hover:bg-white hover:border-none',
        {
          'bg-[#D2DEFF]': overlayPlacement === 'top',
          'bg-[#1F2D5C]/70 border-solid border-[#1F2D5C] border-[1px]':
            overlayPlacement === 'down',
        },
        className
      )}
    >
      <SVG
        src={buttonUrl}
        alt="button-icon"
        className={cx('group-hover/button:hidden', color && `color-[${color}]`)}
        style={color ? { color } : {}}
      />
      {hoverIconUrl && (
        <SVG
          src={hoverIconUrl}
          alt="button-icon"
          className={cx('group-hover/button:block hidden size-4', color && `text-[${color}]`)}
          style={color ? { color } : {}}
        />
      )}
      {text && (
        <span className="text-white text-xs font-medium ml-1 hover:text-[#1C2024]">
          {text}
        </span>
      )}
    </div>
  );
};
