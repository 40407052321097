import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTypeContent } from 'rtk/Slices/dataSlice';
import { SearchBar } from 'components/search-bar';
import { CartDialog } from 'components/search-bar/cart-dialog';
import { ImageSearchDialog } from 'components/search-bar/image-search-dialog';
import User from 'components/header/User';
import ButtonIcon from 'components/header/ButtonIcon';
import BurgerIcon from 'assets/icons/burger.svg';

const categories = [
  { type: 'image', label: 'Изображения' },
  { type: 'video', label: 'Видео' },
  { type: 'audio', label: 'Аудио' },
  { type: 'document', label: 'Документы' },
];

export const Header = () => {
  const [uploadImage, setUploadImage] = useState(false);
  const dispatch = useDispatch();

  const handleCategorySelect = (type) => {
    dispatch(setTypeContent(type));
  };

  return (
    <div className="container mx-auto px-10 pt-[30px] pb-6 flex items-center justify-between sm:flex-col sm:px-5 sm:pt-5 sm:pb-8">
      <div className="w-full flex items-center justify-between">
        <img
          src={BurgerIcon}
          alt="burger-icon"
          className="hidden mr-[11px] sm:block"
        />

        <Link to="/">
          <img
            src="../../images/logo-black.svg"
            alt="logo-icon"
            className="mr-[26px]"
          />
        </Link>

        <SearchBar
          className="mr-3 sm:hidden"
          categories={categories}
          onCategorySelect={handleCategorySelect}
        />
        <ImageSearchDialog
          className="sm:hidden"
          uploadImage={uploadImage}
          setUploadImage={setUploadImage}
        />

        <nav className="header__nav">
          <div className="header__menu">
            <ul className="header__list header__ml">
              <li className="header__item">
                <Link className="header__link" to="#">
                  Планы подписки
                </Link>
              </li>
            </ul>
            <ButtonIcon />
          </div>
          <User />
          <CartDialog categories={categories} />
        </nav>
      </div>

      <SearchBar
        className="hidden sm:flex sm:w-full sm:mt-4"
        categories={categories}
        onCategorySelect={handleCategorySelect}
      />
    </div>
  );
};
