import React, { useEffect, useState } from 'react';
import { SwiperSlide, Swiper } from 'swiper/react';
import Sprite from 'UI/sprite/Sprite';
import { Link } from 'react-router-dom';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import Button from 'UI/button/Button';
import { Spinner } from 'components/Spinner';

function Slider() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await fetch(
        'https://admin.hires-marketplace.ru/api/search?pg=1&srh_field=space&ipp=6&video=only'
      );
      const data = await response.json();
      setVideos(data.result.items.item);
      setLoading(false);
    };

    fetchVideos();
  }, []);

  return (
    <div className="about__block">
      <div className="about__purple"></div>
      <div className="about__top">
        <Button className="about__top-button" count={6}>
          Фильтры
        </Button>
        <div className="about__top-wrapper">
          <h5 className="about__top-title">Видео по запросу «Космос»</h5>
          <p className="about__top-count">25.856.471 материала</p>
        </div>
      </div>
      {loading ? (
        <Spinner className="about__swiper" />
      ) : (
        <Swiper
          className="about__swiper"
          spaceBetween={20}
          slidesPerView={1.3}
          modules={[Autoplay]}
          autoplay={{ delay: 1500 }}
          loop={true}
        >
          {videos.map((video) => (
            <SwiperSlide key={video.imageid} className="about__slide">
              <video
                className="about__video"
                poster={video.videoExtraLargeThumb}
                preload="metadata"
                autoPlay
                muted
                loop
              >
                <source src={video.videoMediumThumb} type="video/mp4" />
                Ваш браузер не поддерживает видео.
              </video>
              <div className="about__slide-wrapper">
                <h5 className="about__slide-name">{video.title}</h5>
                <span className="about__date">00:12</span>
              </div>
              <button className="about__basket">
                <Sprite icon="basket" width={18} height={18} />
              </button>
              <Link className="about__link" to="#">
                <Sprite icon="more" width={15} height={14} />
                <span>Похожее</span>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}

export default Slider;
