export const cards = [
  {
    id: 1,
    title:
      'Top Down View Of Crop Field On A Hill Slow Wind Colorful Still Life Symmetry',
    videoId: '2564875',
    resolution: '854 x 480',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: '18.07.2024',
    licenseTime: '14:30',
    Status: 'Одобрен',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 2,
    title: 'Aerial View of Forest Covered Mountains and Rivers',
    videoId: '2564876',
    resolution: '1920 x 1080',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: '01.01.2025',
    licenseTime: '09:15',
    Status: 'Отклонен',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 3,
    title: 'Mountain Peaks During Sunset',
    videoId: '2564877',
    resolution: '1280 x 720',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: '15.03.2025',
    licenseTime: '12:45',
    Status: 'Отклонен',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 4,
    title: 'City Skyline at Night',
    videoId: '2564878',
    resolution: '3840 x 2160',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: '20.05.2025',
    licenseTime: '16:20',
    Status: 'Одобрен',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 5,
    title: 'Ocean Waves Crashing on the Shore',
    videoId: '2564879',
    resolution: '1920 x 1080',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: '10.08.2025',
    licenseTime: '07:30',
    Status: 'Одобрен',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 6,
    title: 'Sunset Over the Ocean',
    videoId: '2564880',
    resolution: '2560 x 1440',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: '05.07.2025',
    licenseTime: '18:00',
    Status: 'Отклонен',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 7,
    title: 'Fog Over Mountain Hills',
    videoId: '2564881',
    resolution: '1366 x 768',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: '12.09.2025',
    licenseTime: '08:10',
    Status: 'Одобрен',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 8,
    title: 'Snowy Mountain View',
    videoId: '2564882',
    resolution: '1920 x 1080',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: '17.02.2024',
    licenseTime: '18:54',
    Status: 'Одобрен',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
];
