import { Link, useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@radix-ui/react-icons';

export const Breadcrumbs = () => {
  const location = useLocation();

  // Разбиваем путь на части
  const pathParts = location.pathname.split('/').filter(Boolean);

  // Создаём массив с названиями и ссылками
  const breadcrumbs = pathParts.map((part, index) => {
    const url = '/' + pathParts.slice(0, index + 1).join('/');

    // Сопоставляем названия для отображения
    const nameMap = {
      video: 'Видео',
      profile: 'Личный кабинет',
      blog: 'Блог',
      subscription: 'Подписка',
      'shopping-cart': 'Корзина',
      author: 'Автор',
      history: 'История',
      'legal-entity': 'Юр. лицо',
      support: 'Поддержка',
      info: 'Профиль',
      company: 'Данные компании',
      plans: 'Планы подписки',
      payment: 'Платежные данные',
      admin: 'Администрирование',
      settings: 'Настройки',
      historyPurchase: 'История покупок',
      favorites: 'Избранное',
      recentlyViewed: 'Недавно просмотренные',
      collections: 'Коллекции',
      preview: 'Превью',
    };
    

    return {
      name: nameMap[part] || part,
      url,
    };
  });

  return (
    <div className="mt-8 flex items-center gap-4 sm:gap-3 md:mt-0">
      <Link to="/" className="text-[#60646C] text-sm hover:underline">
        Главная
      </Link>
      {breadcrumbs.map((crumb, index) => (
        <div key={crumb.url} className="flex items-center gap-4 sm:gap-3">
          <ChevronRightIcon className="h-4 w-4 sm:h-3 sm:w-3" />
          {index === breadcrumbs.length - 1 ? (
            <span className="font-medium text-[#202020] text-sm">
              {crumb.name}
            </span>
          ) : (
            <Link
              to={crumb.url}
              className="text-[#60646C] text-sm hover:underline"
            >
              {crumb.name}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};
