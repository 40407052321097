import React, { useState, useEffect } from 'react';
import { useLoginMutation } from 'rtk/authApi';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'rtk/Slices/authSlice';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const [login, { isLoading: loginLoading, error: loginError }] = useLoginMutation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Если пользователь уже авторизован, перенаправляем на страницу профиля
  useEffect(() => {
    if (user) {
      navigate('/profile'); // Редирект на профиль
    }
  }, [user, navigate]);

  const handleLogin = async () => {
    try {
      const credentials = { username, password };
      const response = await login(credentials).unwrap();
      dispatch(setUser(response));  // Сохраняем пользователя в store
    } catch (err) {
      console.error('Login failed:', err);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-full p-40 bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold text-center text-gray-700 mb-6">Login</h1>
        <div className="space-y-4">
          <div>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            onClick={handleLogin}
            disabled={loginLoading}
            className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-blue-400"
          >
            {loginLoading ? 'Logging in...' : 'Login'}
          </button>
          {loginError && <p className="mt-2 text-center text-red-500">{loginError.message}</p>}
        </div>
      </div>
    </div>
  );
};
