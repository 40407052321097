import React, { useState } from 'react';
import classnames from 'classnames';
import { ProfileSidebar } from './profile-sidebar';
import { ChevronRightIcon } from '@radix-ui/react-icons';

export function Dropdown({
  url,
  label,
  className,
  iconClassName
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="w-full hidden md:block mb-4">
      <div
        className={classnames(
          'group h-10 flex justify-between items-center pl-[10px] pr-3 rounded-md cursor-pointer md:h-[72px] md:pl-5 md:pr-[10px] md:border-b md:border-solid md:border-[#F0F0F3] bg-[#FCFCFD] ',
          className
        )}
        onClick={toggleDropdown}
      >
        <div className="flex gap-2">
          <img
            src={url}
            alt="icon"
            className="w-5 h-5"
          />
          <div className="text-[#1C2024] text-sm leading-5 md:text-base md:leading-6">
            {label}
          </div>
        </div>

        <ChevronRightIcon
          className={classnames(
            'transition-transform duration-300 ease-in-out w-[24px] h-[24px]',  // Плавный переход
            iconClassName,
            { 'rotate-90': isDropdownOpen }  // Поворот на 90 градусов при открытии
          )}
        />
      </div>

      {isDropdownOpen && (
        <div className="mt-2 flex flex-col gap-2">
          <ProfileSidebar />
        </div>
      )}
    </div>
  );
}
