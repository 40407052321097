import { useState } from 'react';
import { Card } from './Card';
import { cardsData, tabs } from './data'; // Убедитесь, что вы импортируете данные
import { Sidebar } from './Sidebar'; // Ваш компонент сайдбара
import { ChevronLeftIcon } from '@radix-ui/react-icons';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'hooks/useWindowSize';
import { CardTags } from './CardTags';
import UserImage from 'assets/images/avatar.png';

export const BlogProduct = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1080; 
  const [activeTab, setActiveTab] = useState('Котики'); 

  const nonActualCards = cardsData.filter((card) => !card.cardTags.actual);

  return (
    <div className="container mx-auto  flex flex-col sm:px-5">
      <div
        className={`flex ${isMobile ? 'flex-col' : 'flex-row'} mt-10 `}
      >
        {(isMobile || !isMobile) && (
          <Sidebar
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isMobile={isMobile}
          />
        )}

        <div className="flex-1 max-w-full overflow-hidden ml-10 md:ml-0">
          <div className="max-w-[893px]">
            {!isMobile && (
              <Link
                to="/blog"
                className="flex gap-[6px] text-[#1F2D5C] !cursor-pointer pointer-events-auto"
              >
                <ChevronLeftIcon className="w-6 h-6" />
                <h1>Вернуться к блогу</h1>
              </Link>
            )}
            <CardTags
              readingTime={'8 минут '}
              views={'645 просмотров'}
              actual={true}
              className="mt-3 mb-3 lg:mt-8"
            />
            <h1 className="font-bold text-4xl mb-3 md:text-[28px] text-[#1C2024]">
              Полное название статьи короткое не более двух строк в таком виде
            </h1>
            <div className="flex items-center text-[#80838D] text-sm mb-8">
              <img src={UserImage} alt="user" />
              <div className="flex items-center ml-1">
                <div>Автор:</div>
                <div className="ml-[2px] underline text-[#3E63DD]">
                  Constantine
                </div>
              </div>
              <span className="mx-1">&bull;</span>
              <div>2 октября 2024</div>
            </div>

            <h1 className="font-bold text-2xl md:text-xl mb-6 text-[#1C2024]">1. Глава</h1>

            <p className="text-base text-[#1C2024] font-normal">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam
              nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo
              voluptas nulla pariatur?
            </p>

            <img
              alt=""
              src="https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg"
              className="w-full mt-10 mb-10"
            />

            <h1 className="font-bold text-2xl mb-6 text-[#1C2024] md:text-xl">1. Глава</h1>

            <p className="text-base text-[#1C2024] font-normal">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, кто в ea voluptate velit esse quam nihil molestiae
              consequatur, вел illum qui dolorem eum fugiat quo voluptas nulla
              pariatur?
            </p>

            <div>
              <h1 className="font-bold text-4xl md:text-[28px] mb-8 text-[#1C2024] mt-12">
                Смотрите также
              </h1>
            </div>
          </div>
          <div
            className={`grid  gap-x-10 gap-y-[30px] grid-cols-3  sm:grid-cols-1 lg:grid-cols-[auto_auto] lg:place-items-center justify-center mb-16 `}
          >
            {nonActualCards.slice(0, 3).map((card) => (
              <Card key={card.id} card={card} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
