import { SidebarCheckbox } from '../sidebar-checkbox';
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg';
import * as Accordion from '@radix-ui/react-accordion';

export const SidebarAccordionType = ({
  label,
  filterKey,
  options,
  filters,
  handleNestedFilterChange,
}) => {
  return (
    <Accordion.Item className="mb-4" value={label}>
      <Accordion.Trigger className="w-full h-5 flex items-center justify-between">
        <div className="text-sm font-medium text-[#1C2024]">{label}</div>

        <img src={ChevronDownIcon} alt="chevron-down-icon" />
      </Accordion.Trigger>
      <Accordion.Content className="mt-8">
        {options.map((option) => (
          <div key={option.key} className="mt-5 flex flex-col gap-[10px]">
            <SidebarCheckbox
              label={option.label}
              checked={filters[filterKey] === option.key} // Проверяем, выбран ли этот вариант
              onCheckedChange={(value) =>
                handleNestedFilterChange(filterKey, value ? option.key : null)
              } // Убираем старое значение, если новое выбрано
            />
          </div>
        ))}
      </Accordion.Content>
    </Accordion.Item>
  );
};
