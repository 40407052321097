import React from 'react';
import classnames from 'classnames';
import { FormInput } from '../form-input';
import { Button, FormSwitch } from 'components';
import { Dropdown } from '../dropdown';

import UserIcon from 'assets/icons/person.svg';
import PencilIcon from 'assets/icons/pencil.svg';
import CheckCircledIcon from 'assets/icons/check-circled.svg';
import { useSelector } from 'react-redux';

import { ProfileInfo } from './ProfileInfo';

export const ProfileSection = ({ profileNavigation }) => {
  const { user } = useSelector((state) => state.auth); 

  return (
    <div
      className={classnames('w-full flex flex-col', {
      })}
    >
      <Dropdown
        url={UserIcon}
        label="Профиль"
        className="border rounded-xl md:flex"
        iconClassName="rotate-90"
      />
      <ProfileInfo
        className={classnames({
          'md:hidden': profileNavigation,
        })}
        user={user} 
      />

      <form className="max-w-[543px] flex flex-col mt-10 gap-6 md:max-w-full md:mt-8">
        <FormInput label="Имя" url={PencilIcon} />
        <FormInput label="Фамилия" url={PencilIcon} />
        <FormInput label="Отчество" url={PencilIcon} />
        <FormInput label="Почта" url={CheckCircledIcon} />
        <FormInput label="Телефон" url={CheckCircledIcon} />
        <FormInput label="Страна" />

        <FormSwitch label="Скрыть редакционный контент" />

        <Button
          title="Сохранить изменения"
          className="w-[266px] h-[48px] px-6 bg-[#1F2D5C] rounded-lg text-[#FFF] font-medium md:w-full"
        />
      </form>
    </div>
  );
};

