import TickIcon from 'assets/icons/tick.svg';
import Crown from 'assets/icons/crown.svg';
import classnames from 'classnames';


import SVG from 'react-inlinesvg';
import { ProfileTooltip } from './ProfileTooltip'; 

export const ProfileInfo = ({ className, user }) => {
    return (
      <div
        className={classnames(
          'flex items-center cursor-pointer md:flex-col md:items-center',
          className
        )}
      >
        <ProfileTooltip /> {/* Используем компонент тултипа */}
  
        <div className="ml-5 flex flex-col md:ml-0 md:mt-4 md:items-center">
          <div className="flex items-center gap-[9px]">
            <h1 className="font-bold text-[#1C2024] text-[35px] leading-10 -tracking-[0.16px] md:text-[28px] md:leading-9">
              {user?.fullName} {/* Отображаем имя и фамилию пользователя */}
            </h1>
  
            <img src={TickIcon} alt="tick" className="size-6" />
            <div className="p-2 bg-[#FFEE9C] border border-solid border-[#FBE577] rounded-lg text-[#AB6400]">
              <SVG
                src={Crown}
                alt="Администратор"
                className="w-4 h-4"
              />
            </div>
          </div>
  
          <div className="mt-[3px] text-lg leading-[26px] text-[#000714]/60">
            @{user?.userId} {/* Отображаем username */}
          </div>
  
          <div className="mt-[9px] flex items-center gap-1 text-[#80838D] text-sm">
            {user?.id} &#8226; {user?.role.ru} {/* Отображаем телефон и роль пользователя */}
          </div>
        </div>
      </div>
    );
  };
  