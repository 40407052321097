export const navigation = {
  videos: {
    title: 'Видео',
    icon: 'video',
    to: '#',
    type: 'video',
  },
  photos: {
    title: 'Фотографии',
    icon: 'camera',
    to: '#',
    type: 'image',
  },
  music: {
    title: 'Музыка',
    icon: 'music',
    to: '#',
    type: 'audio',
  },
  images: {
    title: 'Иллюстрации',
    icon: 'image',
    to: '#',
    type: 'image',
  },
  sounds: {
    title: 'Звуки',
    icon: 'sound',
    to: '#',
    type: 'audio',
  },
};
