export const CONTENT_TYPE = {
  REEL: 'reel',
  VIDEO: 'video',
  AUDIO: 'audio',
  MUSIC: 'music',
  PHOTOS: 'photo',
  PICTURE: 'picture',
};

export const ACTIVE_TAB = {
  VISUALLY_SIMILAR: 'visually similar',
  OTHER_WORKS: 'other works',
};

export const SUPPORT_ACTIVE_TAB = {
  ALL: 'all',
  OPEN: 'open',
  PENDING: 'pending',
  CLOSED: 'closed',
};

export const CHAT_STATUS = {
  OPEN: 'Открыто',
  CLOSED: 'Закрыто',
  PENDING: 'На рассмотрении',
};

export const LOGIN_ACTIVE_TAB = {
  LOGIN: 'login',
  SIGNIN: 'signin',
};

export const PAYMENT_RADIO_GROUP = {
  CARD: 'card',
  PAY: 'pay',
  QUICK: 'quick',
};

export const SHOPPING_CART_BREAD_CRUMBS = {
  SHOPPING_CART: 'корзина',
  PAYMENT: 'оплата',
  DOWNLOAD: 'скачать',
};

export const PROFILE_NAVIGATION = {
  PROFILE: 'Профиль',
  PLANS: 'Планы подписки',
  PAYMENT: 'Платежные данные',
  SETTINGS: 'Настройки',
  SUPPORT: 'Поддержка',
  EXIT: 'Выйти',
};

export const SIGNUP_USERS = {
  INDIVIDUAL: 'Физическое лицо',
  LEGAL_ENTITY: 'Юридическое лицо',
};
