import React, { useState } from 'react';
import UploadIcon from 'assets/icons/upload.svg';
import CloseIcon from 'assets/icons/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSimilarImages,
  setFileType,
  clearSimilarImages,
  setSearchType,
} from 'rtk/Slices/similarImagesSlice';
import SVG from 'react-inlinesvg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'components/Spinner';
import { setTypeContent } from 'rtk/Slices/dataSlice';

export function Dnd() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const [originalImageUrl, setOriginalImageUrl] = useState(''); 
  // eslint-disable-next-line
  const [description, setDescription] = useState(''); 
  // eslint-disable-next-line
  const [keywords, setKeywords] = useState(''); 

  const { fileType } = useSelector((state) => state.similarImages);
  console.log('fileType', fileType);
  const handleFileUpload = async (file) => {
    if (!file) return;

    const fileType = file.type.split('/')[0];
    console.log(fileType); // Проверка типа файла

    // Проверяем, что файл имеет правильный тип
    if (!['image', 'video', 'audio'].includes(fileType)) {
      toast.error('Поддерживаются только изображения, видео и аудио');
      return;
    }

    // Проверка размера файла (например, максимум 50 МБ)
    const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB
    if (file.size > MAX_FILE_SIZE) {
      toast.error('Файл слишком большой. Максимальный размер: 50MB');
      return;
    }

    dispatch(setSearchType(''));
    dispatch(setFileType(''));

    setLoading(true);
    setErrorMessage('');

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('http://localhost:3004/py/upload', {
        method: 'POST',
        body: formData, // Заголовок Content-Type устанавливается автоматически
      });

      if (!response.ok) {
        // Обработка ошибок с сервера (например, если сервер вернёт статус 400 или 500)
        const errorData = await response.json();
        throw new Error(errorData?.message || response.statusText);
      }

      const data = await response.json();
      if (!data) {
        throw new Error('Полученные данные пустые');
      }

      // Обновляем состояние с полученными данными
      dispatch(setSearchType('file'));
      setFile(file);
      setOriginalImageUrl(data?.originalImageUrl);
      setDescription(data?.description);
      setKeywords(data?.keywords);

      // Устанавливаем тип файла в состояние
      dispatch(setFileType(fileType));
      dispatch(setTypeContent(fileType));
      dispatch(setSimilarImages(data?.similar_images || []));

      toast.success('Файл успешно загружен!');
    } catch (error) {
      console.error('Ошибка при загрузке файла:', error.message);
      setErrorMessage(
        error.message || 'Ошибка при загрузке файла. Попробуйте снова.'
      );
      toast.error(error.message || 'Ошибка при загрузке файла.');
    } finally {
      setLoading(false);
    }
  };

  // Обработка события перетаскивания
  const handleDrop = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    dispatch(setSearchType(''));
    setLoading(true);
    dispatch(setFileType(''));

    const droppedFiles = e.dataTransfer.files; // Получение файлов из события
    if (droppedFiles.length) {
      await handleFileUpload(droppedFiles[0]); // Загружаем первый файл
    } else {
      const droppedUrl = e.dataTransfer.getData('image_url'); // URL изображения

      if (droppedUrl) {
        try {
          const response = await fetch('http://localhost:3004/py/upload', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },

            body: JSON.stringify({ url: droppedUrl }),
          });

          if (!response.ok) throw new Error('Ошибка при загрузке URL');

          const data = await response.json();
          setFile(true);
          setLoading(false);

          setOriginalImageUrl(data?.originalImageUrl);
          setDescription(data?.description);
          setKeywords(data?.keywords);
          dispatch(setSearchType('url'));
          dispatch(setFileType('image'));
          dispatch(setTypeContent('image'));
          dispatch(setSimilarImages(data?.similar_images || []));

          toast.success('Поиск по похожим!');
        } catch (error) {
          console.error('Поиск по похожим:', error.message);
          toast.error('Поиск по похожим.');
        }
      }
    }
  };

  // Обработка удаления файла
  const handleRemoveFile = async () => {
    try {
      const response = await fetch(
        `http://localhost:3004/py/delete/uploads${originalImageUrl}`,
        {
          method: 'DELETE',
        }
      );

      if (!response.ok) throw new Error(response.statusText);

      setFile(null);
      setOriginalImageUrl('');
      setDescription('');
      setKeywords('');

      dispatch(clearSimilarImages());
      toast.info('Файл удалён.');
    } catch (error) {
      console.error('Ошибка при удалении файла:', error.message);
      toast.error('Ошибка удаления файла.');
    }
  };

  const renderFilePreview = () => {
    if (fileType === 'image') {
      return (
        <img
          src={`http://localhost:3004/py/uploads${originalImageUrl}`}
          alt="keywords"
          className="object-contain w-full h-full"
        />
      );
    }
    if (fileType === 'video') {
      return (
        <video controls className="w-full h-full">
          <source
            src={`http://localhost:3004/py/uploads${originalImageUrl}`}
            type="video/mp4"
          />
          Ваш браузер не поддерживает видео.
        </video>
      );
    }
    return null;
  };

  return (
    <div
      className="w-full h-[88px] flex justify-center items-center rounded-lg border-dashed border-[1px] border-[#60646C] cursor-pointer relative"
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      {loading ? (
        <Spinner size="large" color="blue" />
      ) : file ? (
        <div className="w-full h-full relative">
          <div
            className="absolute -top-4 -right-4 p-1 bg-white rounded-full cursor-pointer"
            onClick={handleRemoveFile}
          >
            <SVG className="w-6 h-6 text-gray-500" src={CloseIcon} />
          </div>
          <div className="w-full h-full bg-gray-200 flex justify-center items-center">
            {renderFilePreview()}
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <p className="text-sm text-gray-500">Найти похожие</p>
          <img src={UploadIcon} alt="Upload" />
        </div>
      )}
      <input
        type="file"
        onChange={(e) => handleFileUpload(e.target.files[0])}
        className="hidden"
      />
      <ToastContainer />
    </div>
  );
}
