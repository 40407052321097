import { useState } from 'react';
import classnames from 'classnames';
import { Button } from 'components';
import { Dropdown } from '../dropdown';

import PencilIcon from 'assets/icons/pencil.svg';

import TBankImage from 'assets/icons/banks/t-bank.svg';
import SberBankImage from 'assets/icons/banks/sber-bank.svg';
import AlfaBankImage from 'assets/icons/banks/alfa-bank.svg';
import MirGrayImage from 'assets/icons/banks/mir-gray.svg';

import PlusBlueIcon from 'assets/icons/plus-blue.svg';
import WindowsIcon from 'assets/icons/windows.svg';
import CrossIcon from 'assets/icons/cross.svg';
import { FormInput } from '../../profileold/form-input';

export const PaymentSection = () => {
  const [activeTab, setActiveTab] = useState('check'); 

  return (
    <div className="max-w-[547px] w-full flex flex-col md:max-w-full">
      <Dropdown
        url={WindowsIcon}
        label="Платежные данные"
        className="border rounded-xl md:flex"
        iconClassName="rotate-90"
      />

      <h1 className="mt-[15px] font-bold text-[#1C2024] text-[35px] leading-10 -tracking-[0.16px] md:text-[28px] md:leading-9 md:mt-8">
        Платежные данные
      </h1>

      {/* Табы */}
      <div className="flex mt-8">
        <div
          className={`w-full h-10 flex justify-center items-center cursor-pointer text-base leading-6 font-medium ${
            activeTab === 'check'
              ? 'border-b-2 border-solid border-[#1F2D5C] text-[#1C2024]'
              : 'text-[#80838D] border-b border-solid border-[#F0F0F3]'
          }`}
          onClick={() => setActiveTab('check')}
        >
          Запросить счет по реквизитам
        </div>
        <div
          className={`w-full h-10 flex justify-center items-center cursor-pointer text-base leading-6 font-medium ${
            activeTab === 'card'
              ? 'border-b-2 border-[#1F2D5C] border-solid text-[#1C2024]'
              : 'text-[#80838D] border-b border-solid border-[#F0F0F3]'
          }`}
          onClick={() => setActiveTab('card')}
        >
          Банковские карты
        </div>
      </div>

      {/* Контент таба "Запросить счет по реквизитам" */}
      {activeTab === 'check' && (
        <form className="max-w-[543px] flex flex-col mt-10 gap-6 md:max-w-full md:mt-8">
          <FormInput label="Наименование" placeholder="Введите название организации" url={PencilIcon} />
          <FormInput label="ИНН" placeholder="Введите ИНН" url={PencilIcon} />
          <FormInput label="КПП" placeholder="Введите КПП" url={PencilIcon} />
          <FormInput label="ОГРН" placeholder="Введите ОГРН" />
          <FormInput label="Расчетный счет" placeholder="Введите расчетный счет" />
          <FormInput label="Корреспондентский счет" placeholder="Введите корреспондентский счет" />
          <FormInput label="БИК банка" placeholder="Введите БИК банка"/>
          <FormInput label="Юридический адрес" placeholder="Введите юридический адрес |" placeholderStyle="placeholder:!text-[#383D3F]" />
          <FormInput label="E-mail" />
          <Button
            title="Запросить счет"
            className="w-[266px] h-[48px] px-6 bg-[#1F2D5C] rounded-lg text-[#FFF] font-medium md:w-full"
          />
        </form>
      )}
{activeTab === 'card' && (
  <ul className="w-full flex-1 mt-8 flex flex-col gap-2 sm:hidden ">
    <PaymentItem url={TBankImage} isFirst={true} />
    <PaymentItem url={SberBankImage} />
    <PaymentItem url={AlfaBankImage} />
    <PaymentItem url={SberBankImage} />
    <PaymentItem url={TBankImage} />
  </ul>
)}
      {/* Контент таба "Банковские карты" */}
      {activeTab === 'card' && (
  <ul className="w-full flex-1 mt-8 p-5 flex-col gap-2 sm:flex hidden bg-white rounded-xl">
    <MobilePaymentItem url={TBankImage} isFirst={true} />
    <MobilePaymentItem url={SberBankImage} />
    <MobilePaymentItem url={AlfaBankImage} />
    <MobilePaymentItem url={SberBankImage} />
    <MobilePaymentItem url={TBankImage} />
    <div className="mt-[13px] flex items-center cursor-pointer">
          <img src={PlusBlueIcon} alt="plus-icon" className="size-[11px]" />

          <div className="ml-1 font-bold text-[#1F2D5C] text-sm leading-5">
            Привязать новую карту
          </div>
        </div>
  </ul>
)}
    </div>
  );
};

const PaymentItem = ({ url, isFirst }) => {
  return (
    <li className="relative w-full p-5 flex flex-col bg-white rounded-xl">
      <div className="flex items-center gap-2">
        <div className="h-[43px] w-[68px] flex items-center justify-center border border-solid border-[#F0F0F3] rounded-lg">
          <img src={url} alt="bank-image" className="w-12" />
        </div>

        <div className="flex items-top ">
          <div className="mt-[5px] flex gap-1">
            <span className="size-[3.8px] bg-[#80838D] rounded-full"></span>
            <span className="size-[3.8px] bg-[#80838D] rounded-full"></span>
            <span className="size-[3.8px] bg-[#80838D] rounded-full"></span>
          </div>

          <div className="ml-1.5 text-sm text-[#1C2024] leading-5">9345</div>
        </div>

        {isFirst && (
          <div className="h-4 px-[7px] rounded-[3px] border border-solid border-[#C4E8D1] bg-[#D6F1DF] font-medium text-[8px] text-[#218358] leading-4">
            По умолчанию
          </div>
        )}
      </div>

      <div className="mt-5 flex items-center">
        <div className="flex items-center cursor-pointer">
          <img src={CrossIcon} alt="cross-icon" />
          <div className="ml-[5px] text-xs text-[#80838D] leading-5 underline">
            Удалить
          </div>
        </div>

        {!isFirst && (
          <div className="ml-[15px] text-[#1C2024] text-xs leading-5 underline cursor-pointer">
            Сделать картой по умолчанию
          </div>
        )}
      </div>

      <img
        src={MirGrayImage}
        alt="mir-image"
        className="absolute top-[10px] right-[10px] w-[25px]"
      />
    </li>
  );
};

const MobilePaymentItem = ({ url, isFirst }) => {
  return (
    <li className={classnames(
      "relative w-full px-[9px] py-[7.5px] flex border border-solid border-[#F0F0F3] bg-white rounded-xl cursor-pointer hover:bg-[#F9F9FB]", 
      { 'bg-[#F0F0F3]': isFirst }
    )}>
    
      <div className="h-[43px] w-[68px] flex items-center justify-center border border-solid border-[#F0F0F3] rounded-lg">
        <img src={url} alt="bank-image" className="w-12" />
      </div>

      <div className="mt-[7px] ml-2 flex items-top gap-2">
        <div className="flex items-top">
          <div className="mt-[5px] flex gap-1">
            <span className="size-[3.8px] bg-[#80838D] rounded-full"></span>
            <span className="size-[3.8px] bg-[#80838D] rounded-full"></span>
            <span className="size-[3.8px] bg-[#80838D] rounded-full"></span>
          </div>

          <div className="ml-1.5 text-sm text-[#1C2024] leading-5">9345</div>
        </div>

        {isFirst && (
          <div className="h-4 px-[7px] rounded-[3px] border border-solid border-[#C4E8D1] bg-[#D6F1DF] font-medium text-[8px] text-[#218358] leading-4">
            По умолчанию
          </div>
        )}
      </div>

      <img
        src={MirGrayImage}
        alt="mir-image"
        className="absolute top-[10px] right-[10px] w-[25px]"
      />
    </li>
  );
};

