import classnames from 'classnames';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import { ACTIVE_TAB } from '../../constants';
import {
  ReelContentList,
  VideoContentList,
  AudioContentList,
  ImageContentList,
} from '../../components';
import { useFetchSearchResultsQuery } from '../../rtk/SearchApi';

export const Content = ({
  audioData,
  reelData,
  videoData,
  type,
  contributorname,
}) => {
  const [activeTab, setActiveTab] = useState(ACTIVE_TAB.OTHER_WORKS);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate(); // Инициализируем навигацию

  const { data, isLoading } = useFetchSearchResultsQuery({
    page: 1,
    itemsPerPage: 10,
    typeContent: type,
    filters: activeTab === ACTIVE_TAB.OTHER_WORKS ? { contributorname } : {},
  });

  const item = Array.isArray(data?.result?.items)
    ? data?.result?.items
    : [data?.result?.items];

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleShowAllClick = () => {
    navigate('/search-results', {
      state: {
        typeContent: type,
        filters:
          activeTab === ACTIVE_TAB.OTHER_WORKS ? { contributorname } : {},
      },
    });
  };

  const ContentList = (() => {
    if (type === 'audio') {
      return <AudioContentList type="audio" items={item} className="mt-10" />;
    }
    if (type === 'video') {
      return <VideoContentList items={item} className="mt-10" />;
    }
    if (type === 'image') {
      return <ImageContentList items={item} className="mt-10" />;
    }
    if (type === 'reel') {
      return <ReelContentList items={item} className="mt-10" />;
    }
    return null;
  })();
  return (
    <div className="mt-12 mb-16 flex flex-col sm:mt-8 sm:mb-0">
      <div className="flex justify-between">
        <div className="max-w-[880px] flex flex-1 items-center sm:w-full">
          <div
            className={classnames(
              'max-w-[440px] h-[72px] flex-1 flex justify-center items-center border-solid border-b-[2px] border-[#00002F]/20 font-medium text-xl text-[#000714]/60 cursor-pointer sm:h-10 sm:w-full sm:text-base',
              {
                '!text-[#1C2024] !border-[#1F2D5C]':
                  activeTab === ACTIVE_TAB.OTHER_WORKS,
              }
            )}
            onClick={() => setActiveTab(ACTIVE_TAB.OTHER_WORKS)}
          >
            {windowWidth > 639 ? 'Другие работы автора' : 'Работы автора'}
          </div>
          <div
            className={classnames(
              'max-w-[440px] h-[72px] flex-1 flex justify-center items-center border-solid border-b-[2px] border-[#00002F]/20 font-medium text-xl text-[#000714]/60 cursor-pointer sm:h-10 sm:w-full sm:text-base',
              {
                '!text-[#1C2024] !border-[#1F2D5C]':
                  activeTab === ACTIVE_TAB.VISUALLY_SIMILAR,
              }
            )}
            onClick={() => setActiveTab(ACTIVE_TAB.VISUALLY_SIMILAR)}
          >
            Визуально похожие
          </div>
        </div>
        <div
          className="w-[150px] h-12 border-solid border-[#CDCED6] border rounded-lg flex items-center justify-center font-medium cursor-pointer sm:hidden text-[#1C2024]"
          onClick={handleShowAllClick} // Добавляем обработчик клика
        >
          Показать все
        </div>
      </div>
      {isLoading ? <div>Загрузка...</div> : ContentList}
      <div
        className="hidden w-[150px] h-12 border-solid border-[#CDCED6] border rounded-lg items-center justify-center font-medium cursor-pointer sm:flex sm:mx-auto sm:mb-8 sm:mt-4"
        onClick={handleShowAllClick} // Добавляем обработчик клика
      >
        Показать все
      </div>
    </div>
  );
};
