// store.js
import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './Slices/dataSlice'; // Ваш редюсер для данных
import { api } from './SearchApi'; // Предположительно это ваш SearchApi
import similarImagesReducer from './Slices/similarImagesSlice'; // Редюсер для похожих изображений
import authApi from './authApi'; // Подключаем authApi (импорт через default)
import authReducer from './Slices/authSlice'; // Редюсер для авторизации

export const store = configureStore({
  reducer: {
    data: dataReducer,
    similarImages: similarImagesReducer,
    auth: authReducer, // Подключаем редюсер для авторизации
    [api.reducerPath]: api.reducer, // Подключаем API для поиска
    [authApi.reducerPath]: authApi.reducer, // Подключаем authApi
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, authApi.middleware), // Добавляем middleware для api и authApi
});
