import TickIcon from 'assets/icons/tick.svg';
import Crown from 'assets/icons/crown.svg';
import SVG from 'react-inlinesvg';
import PencilIcon from 'assets/icons/pencil.svg';
import { users } from './data';
import classnames from 'classnames';
import { ChevronDownIcon } from '@radix-ui/react-icons';

export const Limits = () => {
  return (
    <div className="flex flex-col gap-[10px] mt-8">
      {users.map((user) => (
        <div
          key={user.id}
          className="max-w-full xl:flex-col xl:gap-5 flex bg-[#ffffff] pr-6 pl-5 pt-6 pb-6 rounded-xl justify-between"
        >
          <div className="flex items-start md:flex-col md:items-center">
            <div className="size-[102px] flex justify-center items-center rounded-full bg-[#FFEE00]/30 font-medium text-[35px] text-[#9E6C00] md:size-[96px]">
              {user.avatarLetter}
            </div>

            <div className="ml-5 flex flex-col md:ml-0 md:mt-4 md:items-center">
              <div className="flex items-center gap-[9px]">
                <h1 className="font-bold text-[#1C2024] text-2xl md:text-lg">
                  {user.name}
                </h1>
                <img src={TickIcon} alt="tick" className="size-6" />
                <div
                  className={classnames(
                    'p-2 border border-solid rounded-lg text-lg',
                    user.role === 'Администратор'
                      ? 'bg-[#FFEE9C] border-[#FBE577] text-[#AB6400]'
                      : 'bg-[#E8E8E8] border-[#E0E0E0] text-[#646464]'
                  )}
                >
                  <SVG src={Crown} alt={user.role} className="w-4 h-4" />
                </div>
              </div>
              <div className="mt-[3px] text-lg leading-[26px] text-[#000714]/60">
                {user.role}
              </div>

              {/* Кнопка редактирования для ПК */}
              <div className="mt-5  md:hidden md:mt-2 flex items-center gap-1 text-[#1F2D5C] underline text-sm">
                Редактировать <SVG src={PencilIcon} />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[11px]">
            {user.subscriptions.map((subscription, index) => (
              <div
                key={index}
                className="bg-[#F9F9FB] px-4 py-[14px] flex justify-between rounded-lg w-[313px] xl:w-full text-[#1C2024] font-medium"
              >
                {subscription}
                <ChevronDownIcon className="w-4 h-4" />
              </div>
            ))}
          </div>

          {/* Кнопка редактирования для мобильных */}
          <div className="mt-5 md:flex hidden items-center gap-1 text-[#1F2D5C] underline text-sm">
            Редактировать <SVG src={PencilIcon} />
          </div>
        </div>
      ))}
    </div>
  );
};
