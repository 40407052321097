import React from 'react';
import { Link } from 'react-router-dom';

export function Dropdown({ isOpen, buttons }) {
  if (!isOpen) return null;

  return (
    <div className="absolute top-full right-0 mt-2 bg-white border border-gray-300 rounded-md shadow-lg w-48 z-10">
      {buttons.map((button, index) => (
        <div key={index}>
          {button.type === 'link' ? (
            <Link
              to={button.to}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              {button.label}
            </Link>
          ) : (
            <button
              onClick={button.onClick}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            >
              {button.label}
            </button>
          )}
        </div>
      ))}
    </div>
  );
}
