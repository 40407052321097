export const cards = [
  {
    id: 1,
    title:
      'Top Down View Of Crop Field On A Hill Slow Wind Colorful Still Life Symmetry',
    videoId: '2564875',
    resolution: '854 x 480',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: 'Действует по всему миру с 18.07.2024',
    exclusivity: 'нет',
    price: '10 500 ₽',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 2,
    title: 'Aerial View of Forest Covered Mountains and Rivers',
    videoId: '2564876',
    resolution: '1920 x 1080',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: 'Действует по всему миру с 01.01.2025',
    exclusivity: 'нет',
    price: '12 000 ₽',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 3,
    title: 'Mountain Peaks During Sunset',
    videoId: '2564877',
    resolution: '1280 x 720',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: 'Действует по всему миру с 15.03.2025',
    exclusivity: 'да',
    price: '9 000 ₽',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 4,
    title: 'City Skyline at Night',
    videoId: '2564878',
    resolution: '3840 x 2160',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: 'Действует по всему миру с 20.05.2025',
    exclusivity: 'нет',
    price: '15 000 ₽',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 5,
    title: 'Ocean Waves Crashing on the Shore',
    videoId: '2564879',
    resolution: '1920 x 1080',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: 'Действует по всему миру с 10.08.2025',
    exclusivity: 'нет',
    price: '11 000 ₽',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 6,
    title: 'Sunset Over the Ocean',
    videoId: '2564880',
    resolution: '2560 x 1440',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: 'Действует по всему миру с 05.07.2025',
    exclusivity: 'да',
    price: '13 500 ₽',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 7,
    title: 'Fog Over Mountain Hills',
    videoId: '2564881',
    resolution: '1366 x 768',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: 'Действует по всему миру с 12.09.2025',
    exclusivity: 'нет',
    price: '8 000 ₽',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
  {
    id: 8,
    title: 'Snowy Mountain View',
    videoId: '2564882',
    resolution: '1920 x 1080',
    format: 'mp4',
    licenseType: 'Редакционный',
    licenseDetails: 'Действует по всему миру с 01.11.2025',
    exclusivity: 'нет',
    price: '10 500 ₽',
    materialLink: 'Скачать материал',
    licenseLink: 'Скачать лицензию',
    imageUrl:
      'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg',
  },
];
