import React from 'react';
import { useDispatch } from 'react-redux';
import { setTypeContent } from 'rtk/Slices/dataSlice';
import Sprite from '../../UI/sprite/Sprite';
import { navigation } from '../../utils/constants';
import Dropdown from 'rc-dropdown';
import Item from './Item';

function SearchSelect({ selected, setSelected }) {
  const dispatch = useDispatch();

  // Проверяем, существует ли выбранный элемент в navigation
  const item = navigation[selected] || Object.values(navigation)[0]; // Если нет, берём первый элемент

  // Обработчик выбора категории контента
  const handleCategorySelect = (key) => {
    dispatch(setTypeContent(navigation[key].type)); // Обновляем Redux
    setSelected(key); // Меняем текущее состояние
  };

  const list = (
    <ul className="search__list">
      {Object.keys(navigation).map((key) => (
        <Item
          key={key}
          itemKey={key}
          setSelected={() => handleCategorySelect(key)} // Передаём `key`, а не `type`
        />
      ))}
    </ul>
  );

  return (
    <div className="search__select">
      <Dropdown overlay={list} trigger={['click']}>
        <button className="search__select-button" type="button">
          <span>{item.title}</span>
          <Sprite icon="arrow-bottom" width={16} height={16} />
        </button>
      </Dropdown>
    </div>
  );
}

export default SearchSelect;
