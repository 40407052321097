import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareFiltersForRequest } from '../utils/filtersUtils';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/api/',
    // || 'https://hires.flowly.top/api'
  }),
  endpoints: (builder) => ({
    fetchSearchResults: builder.query({
      query: ({
        searchText = '',
        page = 1,
        itemsPerPage = 48,
        filters = {},
        sortBy = 'downloads',
        typeContent = 'video',
      }) => {
        const filtersQuery = prepareFiltersForRequest(filters);

        let query = `search?pg=${page}&srh_field=${encodeURIComponent(searchText)}&ipp=${itemsPerPage}&orderby=${encodeURIComponent(sortBy)}&${encodeURIComponent(typeContent)}=only`;

        const filtersQueryString = Object.entries(filtersQuery)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join('&');

        if (filtersQueryString) {
          query += `&${filtersQueryString}`;
        }

        console.log('Запрос на сервер:', query);
        return query;
      },
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const { useFetchSearchResultsQuery } = api;
