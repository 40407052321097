import classnames from "classnames";

export const Textarea = ({ label, className, textareaClassName, placeholder = "" }) => {
  return (
    <div className={classnames("w-full flex flex-col gap-2", className)}>
      {label && (
        <label htmlFor={label} className="text-[#1C2024] text-xs leading-4">
          {label}
        </label>
      )}

      <div className="w-full px-4 py-2 border border-solid border-[#000932]/10 bg-white/90 rounded-lg">
        <textarea
          id={label || "textarea"} // Если нет label, используем "textarea"
          className={classnames(
            "w-full text-[#000509]/90 text-sm leading-5 resize-none outline-none bg-transparent h-[72px]",
            textareaClassName
          )}
          placeholder={placeholder}
          style={{ height: "72px" }}
        />
      </div>
    </div>
  );
};
