import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useWindowSize } from 'hooks/useWindowSize';
import classnames from 'classnames';

import { ProfileInfo } from './profileSection/ProfileInfo';
import { ProfileSidebar } from './profile-sidebar';

export const LegalEntity = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 767;
  const navigate = useNavigate();
  const location = useLocation();

  const currentRoute = location.pathname.split('/')[2] || 'info';

  const [profileNavigation, setProfileNavigation] = useState(currentRoute);

  useEffect(() => {
    if (
      !isMobile &&
      (location.pathname === '/profile' || location.pathname === '/profile/')
    ) {
      navigate('info', { replace: true });
    }
  }, [location.pathname, navigate, isMobile]);

  useEffect(() => {
    setProfileNavigation(currentRoute);
  }, [currentRoute]);

  const isHidden =
    location.pathname === '/profile/historyPurchase' ||
    location.pathname === '/profile/favorites' ||
    location.pathname === '/profile/preview'  ||
    location.pathname === '/profile/collections'

  return (
    <div className="container flex-1 mx-auto px-10 flex flex-col sm:px-5">
      <div className="mt-10 mb-16 flex gap-4 md:flex-col md:items-center md:mt-8 md:mb-8">
        {isMobile && (
          <ProfileInfo
            className={classnames('md:order-2', {
              'md:hidden': isMobile && location.pathname !== '/',
              hidden: isHidden,
            })}
          />
        )}

        <ProfileSidebar
          className={classnames('md:order-1', {
            'md:hidden': isMobile && location.pathname !== '/profile',
            hidden: isHidden,
          })}
          profileNavigation={profileNavigation}
          setProfileNavigation={setProfileNavigation}
        />

        <div className="flex w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
