import React from 'react'
import { Input } from '../UI/input'
import PencilIcon from 'assets/icons/pencil.svg';
import { Textarea } from '../UI/textarea';
import { Button } from '../UI/button';

export function RedactCollection() {
  return (
    <div>
        <p className='text-[#1C2024] font-medium text-[20px] mb-6'>Редактировать коллекцию</p>
        <Input placeholder="Новая коллекция" url={PencilIcon} className='mb-2'/>
        <Textarea placeholder="Фотографии для моего сайта... |" textareaClassName='h-[72px]' className='mb-6'  />
        <Input placeholder="Введите имя пользователя" label='Добавить совладельцев' className='font-medium text-sm'/>
        <p className='text-[#3A5BC7] bg-[#EDF2FE] w-fit py-2 px-[10px] mt-3 mb-6 rounded-lg'>@user</p>
        <div className='flex flex-col items-center'>

        <Button   text='Сохранить' className='w-full py-[19px] flex justify-center mb-2'/>
        <Button color='white' text='Отменить'  className='w-full py-[19px] flex justify-center mb-[13px] border border-solid border-[#CDCED6]'/>
        <p className='text-[#DC3E42] underline'>
        Удалить коллекцию
        </p>
        </div>
    </div>
  )
}
