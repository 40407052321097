import React from 'react';
import PreviewSVG from 'assets/icons/Preview.svg';
import { CustomCheckbox } from '../UI/checkbox';
import { cards } from './data';
import { SearchInput } from 'components/SearchInput';
import { SortingByOptions } from 'components/filters/sorting';
import { Button } from '../UI/button';
import SVG from 'react-inlinesvg';
import ArrowTopRightSVG from 'assets/icons/arrows/arrow-top-right.svg';

export function DesktopPreview() {
  return (
    <div className="max-w-full">
      <div className="flex justify-between mb-[31px]">
        <div className="flex gap-3 w-full">
          <Button className="px-[29px]" text="Фильтры" />
          <SearchInput
            placeholder="Поиск по ID или ключевым словам"
            classNameInput="w-[403px]"
            className="max-w-[403px]"
          />
        </div>
        <SortingByOptions
          sortedByOptions={['ID', 'Дата создания', 'Изменения']}
          className="max-w-full min-w-[220px]"
        />
      </div>
      <div className="flex ml-[19px] text-[#202020] font-medium mb-[14px] text-[16px]">
        <div className="mr-[180px] flex items-center">
          <CustomCheckbox
            checkedClassName="!size-4"
            checkboxClassName="!size-4"
            className="mr-[10px]"
          />
          Материал
        </div>
        <div className="mr-[263px] text-[16px]">Описание</div>
        <div className="mr-[252px] text-[16px]">Дата запроса</div>
        <div className="mr-[108px] text-[16px]">Статус запроса</div>
        <div className="mr-[100px] text-[16px]">Загрузка</div>
      </div>

      {cards.map((card) => (
        <div key={card.id} className="flex mb-4 bg-[#FFFFFF] rounded-xl">
          <div className="w-[60px] flex items-center justify-center">
            <CustomCheckbox
              checkedClassName="!size-4"
              checkboxClassName="!size-4"
              className="ml-[8px]"
            />
          </div>
          <div className="w-[222px] relative h-[158px] mr-[32px]">
            <img
              src={card.imageUrl}
              alt="alt"
              className="w-full object-cover h-[158px]"
            />
            <div className="absolute top-2 left-2 flex gap-2">
              <img
                src={PreviewSVG}
                alt="Play"
                className="w-4 h-4 cursor-pointer"
              />
            </div>
            <div className="absolute top-[30px] left-4 text-[8px] bg-[#FCFCFD] text-xs font-medium px-[5px] rounded-[4px] text-[#1C2024] py-[3px]">
              Превью
            </div>
          </div>

          <div className="w-[280px] flex flex-col gap-3 text-[14px] mt-[22px]">
            <div className="flex flex-col gap-[1px]">
              <p className="text-[#1C2024]">{card.title}</p>
              <p className="text-[#80838D] text-sm">
                Видео &#8226; ID {card.videoId}
              </p>
            </div>
            <div className="flex flex-col gap-[1px]">
              <p className="text-[#80838D] text-sm">{card.licenseType}</p>
              <p className="text-[#80838D] text-sm">
                {card.resolution} &#8226; {card.format}
              </p>
            </div>
          </div>

          <div className="ml-[57px] mt-[22px] w-[168px] flex flex-col text-[14px]">
            <p className="text-[#1C2024]">{card.licenseDetails}</p>
            <p className="w-[225px] ">{card.licenseTime}</p>
          </div>

          <div className="ml-[157px] mt-[34px] w-[70px] flex flex-col justify-between text-[18px] font-semibold">
            <p
              className={`text-[#1C2024] ${card.Status === 'Одобрен' ? 'text-[#30A46C]' : 'text-[#E5484D]'}`}
            >
              {card.Status}
            </p>
          </div>

          <div className="ml-[121px] flex flex-col gap-[13px] text-sm mt-[27px]">
            <p className="text-[#1F2D5C] underline font-bold">
              {card.materialLink}
            </p>
            <p className="text-[#1F2D5C] underline font-bold">
              {card.licenseLink}
            </p>
            <p className="text-[#1F2D5C] underline font-bold flex flex-row items-end">
              Поделиться <SVG src={ArrowTopRightSVG} />
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
