import React from 'react';
import SVG from 'react-inlinesvg';

import Rocket from 'assets/icons/rocket.svg';
import Clock from 'assets/icons/clock.svg';
import EyeOpen from 'assets/icons/eye-open.svg';

export const CardTags = ({ readingTime, views, actual, className, isMobile }) => {
  return (
    <div className={`flex items-center gap-2 text-sm text-gray-600 ${className}`}>
      {actual && (
        <span className="flex items-center text-[#027864] text-xs gap-1 font-medium bg-[#C8F4E9] p-2 border rounded-[6px] border-solid border-[#B3ECDE]"> 
          <SVG src={Rocket} alt="Актуальное" className="w-4 h-4" />
          <span className='bg-[#E8E8EC]'>Актуальное</span>
        </span>
      )}
      <span className="flex items-center border border-[#E8E8EC] border-solid rounded-[6px] p-2 text-xs gap-1">
        <SVG src={Clock} style={{ color: '#60646C' }} currentColor='#fff' alt="Время чтения" className="w-4 h-4" />
        <span className='text-[#60646C]'>
          {readingTime} {actual && !isMobile && 'чтения'}
        </span>
      </span>
      <span className="flex items-center border border-[#E8E8EC] border-solid rounded-[6px] p-2 text-xs gap-1">
        <SVG src={EyeOpen} alt="Просмотры" className="w-4 h-4" />
        <span className='text-[#60646C]'>{views}</span>
      </span>
    </div>
  );
};
