import React, { useState } from 'react';
import classnames from 'classnames';
import PowerIcon from 'assets/icons/power.svg';
import GearIcon from 'assets/icons/gear.svg';
import PersonIcon from 'assets/icons/person.svg';
import WindowsIcon from 'assets/icons/windows.svg';
import ChatTooltipIcon from 'assets/icons/chat-tooltip.svg';
import CalendarCheckIcon from 'assets/icons/calendar-check.svg';
import { useWindowSize } from 'hooks/useWindowSize';
import Clipboard from 'assets/icons/clipboard.svg';
import Desktop from 'assets/icons/desktop.svg';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'rtk/Slices/authSlice';  // Импортируем экшен logout
import { SidebarItem } from './sidebar-item';
import { Navigate } from 'react-router-dom';

// Навигационные элементы с добавлением ролей
const PROFILE_NAVIGATION_ITEMS = [
  { label: 'Профиль', route: 'info', icon: PersonIcon, roles: ['LegalEntity', 'Individual', 'Subaccount'] },
  { label: 'Данные компании', route: 'company', icon: Clipboard, roles: ['LegalEntity'] },
  { label: 'Планы подписки', route: 'plans', icon: CalendarCheckIcon, roles: ['LegalEntity', 'Individual'] },
  { label: 'Платежные данные', route: 'payment', icon: WindowsIcon, roles: ['LegalEntity', 'Individual'] },
  { label: 'Администрирование', route: 'admin', icon: Desktop, roles: ['LegalEntity'] },
  { label: 'История покупок', route: 'historyPurchase', icon: Desktop, roles: ['Individual'] },

  { label: 'Избранное', route: 'favorites', icon: Desktop, roles: ['Individual'] },
  { label: 'Недавно просмотренные', route: 'lastscreen', icon: Desktop, roles: ['Individual'] },
  
  { label: 'Коллекции', route: 'collections', icon: Desktop, roles: ['Individual'] },
  { label: 'Превью', route: 'preview', icon: Desktop, roles: ['Individual'] },

  { label: 'Настройки', route: 'settings', icon: GearIcon, roles: ['LegalEntity', 'Individual', 'Subaccount'] },
  { label: 'Поддержка', route: 'support', icon: ChatTooltipIcon, roles: ['LegalEntity', 'Individual', 'Subaccount'] },
];

export const ProfileSidebar = ({
  className,
  profileNavigation,
}) => {
  const { width } = useWindowSize();
  const isMobile = width <= 767;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [redirectToLogin, setRedirectToLogin] = useState(false);  // Стейт для отслеживания редиректа

  const dispatch = useDispatch();  // Хук для отправки экшенов
  const { user } = useSelector((state) => state.auth);
  const userRole = user?.role?.en?.trim();

  const getFilteredNavigationItems = () => {
    return PROFILE_NAVIGATION_ITEMS.filter(item =>
      item.roles.includes(userRole)
    );
  };

  const filteredNavigationItems = getFilteredNavigationItems();

  const closeSidebar = () => {
    if (isMobile) setIsSidebarOpen(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout()); // Отправляем экшен для выхода
    setRedirectToLogin(true); // Устанавливаем флаг для редиректа
  };

  if (redirectToLogin) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div
      className={classnames(
        'min-w-[310px] h-[592px] p-[10px] flex flex-col rounded-xl bg-white md:w-full  md:p-0 md:border md:border-solid md:border-[#F0F0F3]',
        className
      )}
    >
      <div className="flex flex-col gap-3 md:gap-0 flex-grow">
        {filteredNavigationItems.map((item, index) => (
          <SidebarItem
            key={index}
            url={item.icon}
            label={item.label}
            route={item.route}
            profileNavigation={profileNavigation}
            isMobile={isMobile}
            closeSidebar={closeSidebar}
          />
        ))}
      </div>

      <SidebarItem
        url={PowerIcon}
        label="Выйти"
        route="exit"
        className="mt-auto"
        onClick={handleLogout} 
      />
    </div>
  );
};
