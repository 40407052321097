import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { GradientOverlay } from '../GradientOverlay';
import { Overlay } from '../Overlay';
import './video-content.css';

export const VideoContentList = ({ items, onVideoUrlHover, onItemClick }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(Array.isArray(items) ? items : [items]);
  }, [items]);

  return (
    <ul className="flex-1 grid grid-cols-4 gap-1.5 sm:grid-cols-1">
      {data.map((item, idx) => (
        <VideoItem
          item={item}
          key={idx}
          onItemClick={onItemClick}
          onVideoUrlHover={onVideoUrlHover}
        />
      ))}
    </ul>
  );
};
const VideoItem = React.memo(({ item, onVideoUrlHover, onItemClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const requestSentRef = useRef(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsVideoPlaying(true);
    if (!requestSentRef.current && onVideoUrlHover) {
      onVideoUrlHover(item.videoLargeThumb);
      requestSentRef.current = true;
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsVideoPlaying(false);
    requestSentRef.current = false;
  };

  const handleVideoReady = () => {
    setVideoReady(true);
  };
  return (
    <li
      className="relative group w-full h-full rounded-xl transform transition-transform duration-200 ease-in-out"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={item?.videoid}
      onClick={() => onItemClick(item)}
    >
      <div className="w-full relative" style={{ paddingBottom: '56.25%' }}>
        <div
          className={`absolute top-0 left-0 w-full h-full object-cover 
            ${isHovered && videoReady ? 'opacity-0' : 'opacity-100'}`}
          style={{ zIndex: isHovered ? 0 : 1 }}
        >
          <img
            src={
              item?.extralargeThumb ||
              item?.largeThumb ||
              item?.meduiThumb ||
              item?.smallThumb
            }
            alt={item?.title}
            className="w-full h-full object-cover rounded-xl"
            loading="lazy"
          />
        </div>

        {isHovered && (
          <div
            className={`absolute top-0 left-0 w-full h-full object-cover 
Б              ${isHovered && videoReady ? 'opacity-100' : 'opacity-0'}`}
            style={{ zIndex: 2 }}
          >
            <ReactPlayer
              url={
                item?.videoExtralargeThumb ||
                item?.videoLargeThumb ||
                item?.videoMeduiThumb ||
                item?.videoSmallThumb
              }
              muted
              loop
              className="react-player w-full h-full object-cover"
              playing={isVideoPlaying}
              width="100%"
              height="100%"
              preload="none"
              onReady={handleVideoReady}
              onError={(error) => console.log('Video error:', error)}
            />
          </div>
        )}
      </div>

      <GradientOverlay />
      <Overlay item={item} />
    </li>
  );
});
