import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import SVG from 'react-inlinesvg'
import CrossSVG from 'assets/icons/cross.svg'

export const Modal = ({ isOpen, onClose,  children, className = "w-[400px]" }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => document.body.classList.remove("overflow-hidden");
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="md:p-5 fixed inset-0 flex items-center justify-center z-50 bg-[#1C2024] bg-opacity-50">
      <div className={`bg-white pt-10 px-5 pb-5 rounded-2xl ${className}`}>
        <div className="flex relative justify-between items-center border-b ">
          <SVG onClick={onClose} className=" absolute -right-3 -top-8 text-gray-600 hover:text-gray-900 text-2xl h-4 w-4" src={CrossSVG}/>
            
        </div>
        <div className="">{children}</div>
      </div>
    </div>,
    document.body
  );
};
