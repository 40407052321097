const NoResults = ({ searchText }) => {
  return (
    <div className="mt-[100px] flex flex-col">
      <h1 className="font-medium text-4xl text-[#1C2024]">
        По запросу “{searchText}” ничего не найдено.
      </h1>
      <ul className="mt-8 text-xl list-disc text-[#000714]/65">
        <li className="list-disc [&::marker]:text-sm">
          Попробуйте перенастроить фильтры.
        </li>
        <li className="list-disc [&::marker]:text-sm">
          Проверьте свой запрос на наличие опечаток.
        </li>
        <li className="list-disc [&::marker]:text-sm">
          Введите запрос по-другому.
        </li>
      </ul>
    </div>
  );
};

export default NoResults;
